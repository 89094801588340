import { FC, useState } from "react";
import { useGetOrganizationsQuery } from "../../store/organizations/api";
import { Form, Select } from "antd";
import { cities, RELEASE_BY } from "../FirmwareConfig/utils";
import { BetaVersionConfigProps } from "./types";

const { Option } = Select;

const BetaVersionConfig: FC<BetaVersionConfigProps> = ({ _releaseBy }) => {
  const organizations = useGetOrganizationsQuery(null).data;
  const [releaseBy, setReleaseBy] = useState(_releaseBy);

  const getComponent = (selected: string): JSX.Element | null => {
    switch (selected) {
      case "organization":
        return (
          <Form.Item label="Organizations" name="organizationsList">
            <Select mode="tags">
              {organizations?.map((org, idx: number) => (
                <Option value={org.OrgId} key={idx}>
                  {org.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      case "location":
        return (
          <Form.Item label="Cities" name="locationsList">
            <Select mode="tags">
              {cities.map((city) => (
                <Option value={city.id} key={city.id}>
                  {city.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      case "node":
        return (
          <Form.Item label="Devices List" name="nodesList">
            <Select mode="tags" />
            <span style={{ fontSize: "12px", color: "silver" }}>
              Type in Mac Address of nodes to be whitelisted
            </span>
          </Form.Item>
        );
      case "site":
        return (
          <Form.Item label="Sites List" name="sitesList">
            <Select mode="tags" />
            <span style={{ fontSize: "12px", color: "silver" }}>
              Sites will need to be whitelisted by dev team
            </span>
          </Form.Item>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Form layout="vertical" wrapperCol={{ span: 6 }}>
        <Form.Item label="Release By" name="releaseBy">
          <Select
            value={releaseBy}
            onChange={(newValue: string) => setReleaseBy(newValue)}
          >
            {RELEASE_BY.map((key: string, index) => (
              <Option value={key} key={index}>
                {key}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {getComponent(releaseBy)}
      </Form>
    </div>
  );
};

export default BetaVersionConfig;
