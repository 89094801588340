import { QuantityUnit } from "../../helpers/unitsHelpers";
import { LeakData, NodeData } from "../../store/sites/types";

export const mockCustomerAlarmIncidents = [
  {
    OrgId: "ce42d519-c258-4292-a54d-2373f865a5e0",
    orgName: "DTest (Dev)",
    SiteId: "2ad4b246-8d36-4dbf-b232-1c7162800bf9",
    name: "Coffey",
    active: false,
    timezone: "CST",
    isSingleNode: true,
    status: "Ok",
    source: "",
    startTime: "",
    leakRate: "0.1",
    sendStatusChecks: true,
    requireDroneImage: false,
    isMultipleGrid: false,
    config: {
      algorithm: 3,
      paqsAlarms: true,
      var1cut: 60000,
      var3cut: 60000,
      emissionUnits: QuantityUnit.KG,
      stallP: false,
      stallA: false,
      stallB: false,
      ppmCO: 0,
      usingAPI: true,
      hitOf6: 0,
      flagTrip: 0,
    },
    details: {
      latAPI: "35.565284",
      longAPI: "-98.108833",
      nearCity: "Dallas",
      feetElevation: "0",
      installDate: "2023-01-01",
      leakData: [["1", 0, 0, 0]] as LeakData,
      nodeData: [[0, 0, 0]] as NodeData,
      nodes: 0,
      paM: 0,
      realLeaks: 0,
      siteName: "Coffey",
    },
    key: "2ad4b246-8d36-4dbf-b232-1c7162800bf9",
  },
  {
    OrgId: "ce42d519-c258-4292-a54d-2373f865a5e0",
    orgName: "DTest (Dev)",
    SiteId: "b3262f30-01a0-4650-965d-4e8452f248ab",
    name: "Daughety",
    active: false,
    timezone: "CST",
    isSingleNode: true,
    status: "Ok",
    source: "",
    startTime: "",
    leakRate: "0.2",
    sendStatusChecks: true,
    requireDroneImage: false,
    isMultipleGrid: false,
    config: {
      algorithm: 3,
      paqsAlarms: true,
      var1cut: 60000,
      var3cut: 60000,
      emissionUnits: QuantityUnit.KG,
      stallP: false,
      stallA: false,
      stallB: false,
      ppmCO: 0,
      usingAPI: true,
      hitOf6: 0,
      flagTrip: 0,
    },
    details: {
      latAPI: "35.651012",
      longAPI: "-98.205785",
      nearCity: "Dallas",
      feetElevation: "0",
      installDate: "2023-01-01",
      leakData: [["1", 0, 0, 0]] as LeakData,
      nodeData: [[0, 0, 0]] as NodeData,
      nodes: 0,
      paM: 0,
      realLeaks: 0,
      siteName: "Daughety",
    },
    key: "b3262f30-01a0-4650-965d-4e8452f248ab",
  },
  {
    OrgId: "ce42d519-c258-4292-a54d-2373f865a5e0",
    orgName: "DTest (Dev)",
    SiteId: "1c8d2b2f-f78e-46a9-b2c8-9e46533e4ea6",
    name: "Multi Grid",
    active: false,
    timezone: "CST",
    isSingleNode: true,
    status: "Ok",
    source: "",
    startTime: "",
    leakRate: "",
    sendStatusChecks: true,
    requireDroneImage: false,
    isMultipleGrid: true,
    config: {
      algorithm: 3,
      paqsAlarms: true,
      var1cut: 60000,
      var3cut: 60000,
      emissionUnits: QuantityUnit.KG,
      stallP: false,
      stallA: false,
      stallB: false,
      ppmCO: 0,
      usingAPI: true,
      hitOf6: 0,
      flagTrip: 0,
    },
    details: {
      latAPI: "32.175844",
      longAPI: "-93.712178",
      nearCity: "Dallas",
      feetElevation: "0",
      installDate: "2023-01-01",
      leakData: [["1", 0, 0, 0]] as LeakData,
      nodeData: [[0, 0, 0]] as NodeData,
      nodes: 0,
      paM: 0,
      realLeaks: 0,
      siteName: "Multi Grid",
    },
    key: "1c8d2b2f-f78e-46a9-b2c8-9e46533e4ea6",
  },
];
