export const mockDailyEmissions = [
  {
    "90DayAvg(MCF)": 0.511,
    Timestamp: "2024-10-01",
    "7DayAvg(MCF)": 0.378,
    SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
    "totalEmission(MCF)": 0.041,
  },
  {
    "90DayAvg(MCF)": 0.507,
    Timestamp: "2024-10-02",
    "7DayAvg(MCF)": 0.299,
    SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
    "totalEmission(MCF)": 0.297,
  },
  {
    "90DayAvg(MCF)": 0.513,
    Timestamp: "2024-10-03",
    "7DayAvg(MCF)": 0.343,
    SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
    "totalEmission(MCF)": 0.814,
  },
];
