import {
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import React from "react";

export interface StatusTagProps {
  siteStatus: string;
  isActive: boolean;
}

export interface SiteStatus {
  label: string;
  color: string;
  pushpinRadiusPx: number;
  antdIcon: React.ComponentType;
}

export const siteStatuses: Record<string, SiteStatus> = {
  Alarm: {
    label: "Alarm",
    color: "red",
    pushpinRadiusPx: 13,
    antdIcon: CloseCircleOutlined,
  },
  Alert: {
    label: "Searching",
    color: "blue",
    pushpinRadiusPx: 10,
    antdIcon: ExclamationCircleOutlined,
  },
  HighPpm: {
    label: "Searching",
    color: "blue",
    pushpinRadiusPx: 10,
    antdIcon: ExclamationCircleOutlined,
  },
  Ok: {
    label: "OK",
    color: "green",
    pushpinRadiusPx: 10,
    antdIcon: CheckCircleOutlined,
  },
};
