import { FC } from "react";
import { Collapse, Table } from "antd";
import { EventConfidenceProps } from "./types";
import {
  EventConfidence,
  NodeChecks,
  ConfidenceCheck,
  EventConfidencePayload,
} from "../../store/eventConfidence/types";
import { useGetEventConfidenceQuery } from "../../store/eventConfidence/api";
import styles from "./styles.module.scss";

const EventConfidenceDisplay: FC<EventConfidenceProps> = ({
  siteId,
  timestamp,
  nodes,
  coefficientofVariation,
  confidencePercentage,
}) => {
  const eventConfidencePayload: EventConfidencePayload = {
    siteId,
    timestamp,
  };
  const {
    data: resultEventConfidence,
    error,
    isLoading,
  } = useGetEventConfidenceQuery(eventConfidencePayload);
  if (isLoading) return <div>Loading...</div>;
  if (error != null) {
    console.log(JSON.stringify(error));
    return (
      <div>
        There was an error getting Event Confidence. Please contact
        info@earthview.io
      </div>
    );
  }

  const confidenceResult: EventConfidence[] =
    resultEventConfidence?.eventConfidence !== undefined
      ? resultEventConfidence.eventConfidence
      : [];
  if (confidenceResult.length > 0) {
    const thisConfidenceResult = confidenceResult[0];
    const siteChecks: Partial<EventConfidence> = {
      EquipmentTimeOfDay: thisConfidenceResult.EquipmentTimeOfDay,
      EventDuration: thisConfidenceResult.EventDuration,
      EventMagnitude: thisConfidenceResult.EventMagnitude,
      SiteCoverage: thisConfidenceResult.SiteCoverage,
      VocCheck: thisConfidenceResult.VocCheck,
    };
    const nodeChecks: NodeChecks = thisConfidenceResult.NodeChecks;
    const siteCheckTableData = Object.entries(siteChecks).map(
      ([key, value]) => ({
        key,
        ConfidenceScore: (value as ConfidenceCheck).ConfidenceScore,
        Description: (value as ConfidenceCheck).Description,
      })
    );
    const formatKeyToReadableString = (key: string): string => {
      return key
        .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
        .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
        .replace(/(\d)\s*(vs)/g, "$1 $2");
    };
    const columns = [
      {
        title: "Check",
        dataIndex: "key",
        key: "key",
        render: (text: string) => formatKeyToReadableString(text),
      },
      {
        title: "Confidence Score (%)",
        dataIndex: "ConfidenceScore",
        key: "ConfidenceScore",
        render: (score: number) => `${(score * 100).toFixed(1)}%`,
      },
      {
        title: "Description",
        dataIndex: "Description",
        key: "Description",
      },
    ];

    const getRowClassName = (_: unknown, index: number): string => {
      return index % 2 === 0 ? styles.EvenRow : styles.OddRow;
    };

    return (
      <div>
        <h1>Total Score</h1>
        <div key="confidenceCheck" className={styles.Collapsible}>
          <Collapse
            accordion
            items={[{
              key: "ConfidencePannel",
              label: "Confidence",
              children: (
                <>
                  <div style={{ marginBottom: "20px" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Coefficient of Variation:
                    </span>{" "}
                    {coefficientofVariation == null ? 0 : coefficientofVariation.toFixed(2)}
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Confidence:</span>{" "}
                    {confidencePercentage == null
                      ? 0
                      : confidencePercentage.toFixed(1)}
                    %
                  </div>
                </>
              )
            }]}
          />
        </div>
        <h1>Site Checks</h1>
        <div key="siteEventCheck" className={styles.Collapsible}>
          <Collapse
            accordion
            items={[{
              key: "SiteChecks",
              label: "Site Checks",
              children: (
                <Table
                  columns={columns}
                  dataSource={siteCheckTableData}
                  pagination={false}
                  rowClassName={getRowClassName}
                />
              )
            }]}
          />
        </div>
        <h1>Node Checks</h1>
        {Object.keys(nodeChecks).map((key) => {
          const thisNodeChecks = nodeChecks[key];
          const thisNode = nodes.find((x) => x.NodeId === key);
          const header =
            thisNode !== undefined
              ? `${thisNode.name} - ${thisNode.NodeId}`
              : key;
          const tableData = Object.entries(thisNodeChecks).map(
            ([subKey, value]) => ({
              key: subKey,
              ConfidenceScore: (value as ConfidenceCheck).ConfidenceScore,
              Description: (value as ConfidenceCheck).Description,
            })
          );

          return (
            <div key={key} className={styles.Collapsible}>
              <Collapse
                accordion
                items={[{
                  key,
                  label: header,
                  children: (
                    <Table
                      columns={columns}
                      dataSource={tableData}
                      pagination={false}
                      rowClassName={getRowClassName}
                    />
                  )
                }]}
              />
            </div>
          );
        })}
      </div>
    );
  } else {
    return <div>No confidence report for event</div>;
  }
};

export default EventConfidenceDisplay;
