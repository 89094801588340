import { FC } from "react";
import { Tag } from "antd";
import { StatusTagProps, siteStatuses } from "./types";

const tagStyle = {
  fontWeight: 500,
  fontSize: "14px",
  opacity: 0.85,
  padding: "2px",
};

const StatusTag: FC<StatusTagProps> = ({ siteStatus, isActive }) => {
  if (isActive) {
    const siteStatusDict = siteStatuses[siteStatus] ?? siteStatuses.Ok;
    const Icon = siteStatusDict.antdIcon;
    return (
      <Tag
        style={tagStyle}
        color={siteStatusDict.color}
        icon={<Icon/>}
      >
        {siteStatusDict.label}
      </Tag>
    );
  } else {
    return <></>;
  }
};

export default StatusTag;
