import { FC, useState } from "react";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import styles from "./styles.module.scss";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { removePushPins } from "../../pages/OrganizationMap/mapApi";
import SiteForm from "../SiteForm/SiteForm";
import { useAppSelector } from "../../store/hooks";
import { currentOrganizationSelector } from "../../store/organizations/selector";
import { useCreateSiteMutation } from "../../store/sites/api";
import { Site } from "../../store/sites/types";
import { QuantityUnit } from "../../helpers/unitsHelpers";
import * as uuid from "uuid";
import { Coordinates } from "../MapController/types";
import { InitialFormValues } from "../SiteForm/types";
import {
  NewSiteFormResult,
  APIResponse,
} from "../../pages/Sites/NewSite/types";

interface CreateSiteProps {
  isAddSiteOn: boolean;
  handleAddSiteInitiation: () => void;
  setIsAddSiteOn: (x: boolean) => void;
  onClickSites: () => void;
  point: Coordinates;
}

export const CreateSite: FC<CreateSiteProps> = ({
  isAddSiteOn,
  handleAddSiteInitiation,
  setIsAddSiteOn,
  onClickSites,
  point,
}) => {
  const currentOrganization = useAppSelector(currentOrganizationSelector);
  const [createSite] = useCreateSiteMutation();
  const [intitialFormValues, setInitialFormValues] =
    useState<InitialFormValues>({
      SiteId: uuid.v4(),
      siteName: "",
      timezone: "",
      algorithm: 3,
      flagTrip: 0.0,
      paqsAlarms: true,
      ppmCO: 3,
      stallP: false,
      stallA: false,
      stallB: false,
      usingAPI: false,
      var1cut: 60000,
      var3cut: 60000,
      emissionUnits: QuantityUnit.KG,
      latAPI: "30",
      longAPI: "-90",
      requireDroneImage: false,
      sendStatusChecks: true,
      isMultipleGrid: false,
    });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formKey, setFormKey] = useState(0);
  const handleCancel = (): void => {
    setIsAddSiteOn(false);
    removePushPins();
    onClickSites();
  };
  const handleConfirmLocation = (): void => {
    if (point?.latitude !== undefined && point?.longitude !== undefined) {
      setFormKey((prevKey) => prevKey + 1);
      setIsModalOpen(true);
      setInitialFormValues({
        ...intitialFormValues,
        latAPI: Number(point.latitude).toFixed(6),
        longAPI: Number(point.longitude).toFixed(6),
        SiteId: uuid.v4(),
      });
    } else {
      window.alert("Please select a point on the map");
    }
  };
  const handleModalCancel = (): void => {
    setIsModalOpen(false);
  };
  const handleModalOk = (values: NewSiteFormResult): void => {
    if (currentOrganization !== null) {
      const site: Site = {
        OrgId: currentOrganization.OrgId,
        SiteId: values.SiteId,
        name: values.siteName,
        timezone: values.timezone,
        active: false,
        isSingleNode: true,
        requireDroneImage: false,
        sendStatusChecks: true,
        isMultipleGrid: values.isMultipleGrid,
        config: {
          algorithm: values.algorithm,
          ppmCO: values.ppmCO,
          usingAPI: values.usingAPI,
          hitOf6: values.hitOf6,
          flagTrip: values.flagTrip,
          paqsAlarms: values.paqsAlarms,
          var1cut: values.var1cut,
          var3cut: values.var3cut,
          stallP: values.stallP,
          stallA: values.stallA,
          stallB: values.stallB,
          emissionUnits: values.emissionUnits,
        },
        details: {
          latAPI: values.latAPI,
          longAPI: values.longAPI,
        },
      };
      createSite(site)
        .unwrap()
        .then((response: APIResponse | null) => {
          if (
            response !== null &&
            response.Message === "SUCCESS" &&
            currentOrganization !== null
          ) {
            window.alert("Site Created Successfully");
            setFormKey((prevKey) => prevKey + 1);
            setIsModalOpen(false);
            onClickSites();
          }
        })
        .catch((err) => {
          console.log(err);
          window.alert(
            "Error while creating site. Please try again later or contact Earthview"
          );
        });
    }
  };
  const handleModalFail = (info: ValidateErrorEntity): void => {
    console.log("Failed", info);
  };

  return (
    <div>
      {!isAddSiteOn && (
        <div className={styles.AddSiteButtonWrapper}>
          <Button
            className={styles.AddSiteButton}
            onClick={() => handleAddSiteInitiation()}
          >
            Add New Site
            <PlusCircleOutlined className={styles.AddSiteIcon} />
          </Button>
        </div>
      )}
      {isAddSiteOn && (
        <div className={styles.ConfirmSiteButtonWrapper}>
          <div>Select Coordinates on Map then confirm</div>
          <div className={styles.AddSiteButtonWrapper}>
            <Button onClick={() => handleCancel()}>Cancel</Button>
            <Button type="primary" onClick={() => handleConfirmLocation()}>
              Confirm
            </Button>
          </div>
        </div>
      )}
      <Modal
        className={styles.CreateSiteModal}
        open={isModalOpen}
        onCancel={handleModalCancel}
        footer={null}
      >
        <SiteForm
          onFinish={handleModalOk}
          onFinishFailed={handleModalFail}
          onCancel={handleModalCancel}
          initialValues={intitialFormValues}
          formKey={formKey}
        />
      </Modal>
    </div>
  );
};
