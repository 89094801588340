import { Switch, Table, Input, Tooltip } from "antd";
import { FC, useState, useEffect, ChangeEvent } from "react";
import { useAppDispatch } from "../../store/hooks";
import { setGoBackRoute, setPageTitle } from "../../store/ui/slice";
import type { ColumnsType } from "antd/es/table";
import { setCurrentOrganization } from "../../store/organizations/slice";
import { Organization } from "../../store/organizations/types";
import { setCurrentSite } from "../../store/sites/slice";
import { useNavigate } from "react-router-dom";
import { useGetCurrentUserQuery } from "../../store/auth/api";
import { useGetCustomerAlarmIncidentsQuery } from "../../store/alarmIncidents/api";
import { AlarmIncidentParams } from "../../store/alarmIncidents/types";
import StatusTag from "../../components/StatusTags/StatusTags";

interface CustomerSitesProps {
  isTest?: boolean;
}

interface TableItem {
  orgName: string;
  name: string;
  active: boolean;
  SiteId: string;
  timezone: string;
  country: string;
  orgId: string;
  key: string;
  status: string;
  leakRate: number;
  source: string;
  startTime: string;
}

const CustomerSites: FC<CustomerSitesProps> = ({ isTest = false }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useGetCurrentUserQuery(null);
  let isAdmin = false;
  if (userData?.data !== undefined) {
    isAdmin = userData.data.isAdmin;
  }
  let userOrgs = userData.data?.organizations ?? [];
  for (const org of userOrgs) {
    // remove this when string[] is removed from User.organizations
    if (typeof org === "string") userOrgs = [];
  }
  const orgIds = (userOrgs as Organization[]).map((x: Organization) =>
    String(x.OrgId)
  );
  const params: AlarmIncidentParams = {
    ids: String(orgIds?.join(",")),
  };
  const { data, isLoading, isError } =
    useGetCustomerAlarmIncidentsQuery(params);
  const tableData: TableItem[] = [];
  const [filteredTableData, setFilteredTableData] = useState<TableItem[]>([]);

  if (!isError && !isLoading && data !== undefined) {
    for (const org of data) {
      for (const element of org.sites) {
        tableData.push({
          orgName: org.orgName,
          name: element.name,
          SiteId: element.SiteId,
          active: element.active,
          timezone: element.timezone,
          country: org.country,
          orgId: org.OrgId,
          key: element.SiteId,
          status: element.status,
          leakRate: Number(element.leakRate),
          source: element.source,
          startTime: element.startTime,
        });
      }
    }
  }

  const onSelectSite = (orgId: string, siteId: string): void => {
    const org = data?.find((element) => element.OrgId === orgId);
    if (org !== undefined) {
      const site = org.sites.find((element) => element.SiteId === siteId);
      if (site !== undefined) {
        const orgObject = {
          OrgId: org.OrgId,
          name: org.orgName,
          country: org.country,
        };
        dispatch(setCurrentOrganization(orgObject));
        dispatch(setCurrentSite(site));
        site.isSingleNode ?? false
          ? navigate(`/sites/${site.SiteId}`)
          : navigate(`/sites/${site.SiteId}`);
      }
    }
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    const search = e.target.value.toLowerCase();
    setFilteredTableData(
      tableData.filter((siteObj) => {
        return siteObj.name.toLowerCase().includes(search);
      })
    );
  };

  let columns: ColumnsType<TableItem> = [
    {
      title: "Facility Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: TableItem) => (
        <a onClick={() => onSelectSite(record.orgId, record.SiteId)}>{text}</a>
      ),
      sorter: (a, b): number => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Organization",
      dataIndex: "orgName",
      key: "orgName",
      render: (text: string) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string, record: TableItem) => {
        return <StatusTag siteStatus={status} isActive={record.active} />;
      },
    },
    {
      title: "Leak Rate (MCF/Hour)",
      dataIndex: "leakRate",
      key: "leakRate",
      render: (text: string) => <span>{text ?? "-"} </span>,
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (text: string) => <span>{text ?? "-"}</span>,
    },
    {
      title: "Likely Source",
      dataIndex: "source",
      key: "source",
      render: (text: string) => <span>{text ?? "-"}</span>,
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (active: boolean, record: TableItem) => {
        return <Switch defaultChecked={active} disabled />;
      },
    },
  ];
  if (!isAdmin) {
    columns = columns.filter(
      (col) => col.key !== "active" && col.key !== "orgName"
    );
  }

  const dataSource = isTest ? tableData : filteredTableData;

  useEffect(() => {
    dispatch(setPageTitle("Sites"));
    dispatch(setGoBackRoute(""));
  }, []);

  useEffect(() => {
    setFilteredTableData(tableData);
  }, [isLoading]);

  return (
    <div>
      <div style={{ width: "17.5rem", paddingBottom: "10px" }}>
        <Tooltip title="Search by Facility Name">
          <Input
            placeholder="Search..."
            allowClear
            onChange={(event) => onChangeSearch(event)}
          />
        </Tooltip>
      </div>
      <Table columns={columns} dataSource={dataSource} loading={isLoading} />
    </div>
  );
};

export default CustomerSites;
