import { FC, useState, useEffect } from "react";
import SiteForm from "../../../components/SiteForm/SiteForm";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { currentOrganizationSelector } from "../../../store/organizations/selector";
import { currentSiteSelector } from "../../../store/sites/selector";
import { setGoBackRoute, setPageTitle } from "../../../store/ui/slice";
import { Tabs } from "antd";
import "./styles.module.scss";
import TabPanel from "../../../components/TabPanel/TabPanel";
import SiteStats from "../../SiteStats/SiteStats";
import DownloadRequest from "../../../components/DownloadRequest/DownloadRequest";
import Nodes from "../../../components/Nodes/Nodes";
import SiteBaseline from "../../../components/SiteBaseline/SiteBaseline";
import { useGetCurrentUserQuery } from "../../../store/auth/api";
import { NewSiteFormResult } from "../NewSite/types";
import { useUpdateSiteMutation } from "../../../store/sites/api";
import { setCurrentSite } from "../../../store/sites/slice";
import { useLazyGetCustomerAlarmIncidentsQuery } from "../../../store/alarmIncidents/api";
import { QuantityUnit } from "../../../helpers/unitsHelpers";
import SiteAlarmsConfig from "../../SiteAlarmsConfig/SiteAlarmsConfig";
import {
  useLazyGetSiteNodesQuery,
  useUpdateSiteNodeMutation,
} from "../../../store/nodes/api";

const SingleNodeSite: FC = () => {
  const dispatch = useAppDispatch();
  const currentSite = useAppSelector(currentSiteSelector);
  const currentOrg = useAppSelector(currentOrganizationSelector);
  const { data } = useGetCurrentUserQuery(null);
  const siteEmissionUnits =
    currentSite?.config?.emissionUnits ?? QuantityUnit.MCF;
  const [units, setUnits] = useState(siteEmissionUnits);
  const [updateSite] = useUpdateSiteMutation();
  const [custAlarmIncidentsQuery] = useLazyGetCustomerAlarmIncidentsQuery();
  const [getSiteNodesQuery] = useLazyGetSiteNodesQuery();
  const [updateNode] = useUpdateSiteNodeMutation();

  let OrgId = "";
  let active = false;
  let isSingleNode = false;

  if (currentOrg !== null) {
    OrgId = currentOrg.OrgId;
  }

  if (currentSite !== null) {
    active = currentSite.active;
    if (currentSite.isSingleNode !== undefined) {
      isSingleNode = currentSite.isSingleNode;
    }
  }

  let isAdmin = false;

  if (data !== undefined) {
    isAdmin = data?.isAdmin;
  }

  const initialFormValues = {
    SiteId: "",
    siteName: "",
    timezone: "",
    algorithm: 3,
    flagTrip: 0.0,
    hitOf6: 4,
    paqsAlarms: true,
    ppmCO: 3,
    stallP: false,
    stallA: false,
    stallB: false,
    usingAPI: false,
    var1cut: 60000,
    var3cut: 60000,
    emissionUnits: QuantityUnit.MCF,
    latAPI: "30",
    longAPI: "-90",
    requireDroneImage: false,
    sendStatusChecks: true,
    isMultipleGrid: false,
  };

  if (currentSite !== null) {
    initialFormValues.SiteId = currentSite.SiteId;
    initialFormValues.siteName = currentSite.name;
    initialFormValues.timezone = currentSite.timezone;
    initialFormValues.algorithm = currentSite.config.algorithm;
    initialFormValues.flagTrip = currentSite.config.flagTrip;
    initialFormValues.hitOf6 = currentSite.config.hitOf6;
    initialFormValues.paqsAlarms = currentSite.config.paqsAlarms;
    initialFormValues.ppmCO = currentSite.config.ppmCO;
    initialFormValues.stallP = currentSite.config.stallP;
    initialFormValues.stallA = currentSite.config.stallA;
    initialFormValues.stallB = currentSite.config.stallB;
    initialFormValues.usingAPI = currentSite.config.usingAPI;
    initialFormValues.sendStatusChecks = currentSite.sendStatusChecks;
    initialFormValues.requireDroneImage = currentSite.requireDroneImage;
    initialFormValues.isMultipleGrid = currentSite.isMultipleGrid;
    initialFormValues.var1cut = currentSite.config.var1cut;
    initialFormValues.var3cut = currentSite.config.var3cut;
    initialFormValues.emissionUnits =
      currentSite.config?.emissionUnits ?? QuantityUnit.MCF;
    initialFormValues.latAPI = currentSite.details?.latAPI ?? "90";
    initialFormValues.longAPI = currentSite.details?.longAPI ?? "-30";
  }

  const onFinishEditSite = async (values: NewSiteFormResult): Promise<void> => {
    const site = {
      SiteId: values.SiteId,
      name: values.siteName,
      timezone: values.timezone,
      OrgId,
      requireDroneImage: values.requireDroneImage,
      sendStatusChecks: values.sendStatusChecks,
      isMultipleGrid: values.isMultipleGrid,
      config: {
        algorithm: values.algorithm ?? initialFormValues.algorithm,
        flagTrip: values.flagTrip ?? initialFormValues.flagTrip,
        hitOf6: values.hitOf6 ?? initialFormValues.hitOf6,
        paqsAlarms: values.paqsAlarms ?? initialFormValues.paqsAlarms,
        ppmCO: values.ppmCO ?? initialFormValues.ppmCO,
        stallA: values.stallA ?? initialFormValues.stallA,
        stallB: values.stallB ?? initialFormValues.stallB,
        stallP: values.stallP ?? initialFormValues.stallP,
        usingAPI: values.usingAPI ?? initialFormValues.usingAPI,
        var1cut: values.var1cut ?? initialFormValues.var1cut,
        var3cut: values.var3cut ?? initialFormValues.var3cut,
        emissionUnits: values.emissionUnits ?? initialFormValues.emissionUnits,
      },
      details: {
        latAPI: values.latAPI ?? initialFormValues.latAPI,
        longAPI: values.longAPI ?? initialFormValues.longAPI,
      },
      active,
      isSingleNode,
    };
    updateSite(site)
      .unwrap()
      .then(async (res) => {
        window.alert("Site Updated Successfully");
        if (currentSite !== null) {
          dispatch(setCurrentSite({ ...currentSite, ...site }));
        }
        if (OrgId !== "") {
          await custAlarmIncidentsQuery({ ids: OrgId }).unwrap();
        }
      })
      .catch((err) => {
        window.alert(
          "Error while updating site. Please try again or contact the administrator"
        );
        console.log(err);
      });
    // Update Node items with new sendStatusCheck boolean
    const siteNodesQueryResult = await getSiteNodesQuery({ id: values.SiteId });
    const siteNodes = siteNodesQueryResult.data?.result ?? [];
    for (const node of siteNodes) {
      const updatedNode = {
        ...node,
        sendStatusChecks: values.sendStatusChecks,
      };
      updateNode(updatedNode)
        .unwrap()
        .then((response) => {
          if (response?.result?.Message !== "SUCCESS") {
            console.log("Error updating node");
          }
        })
        .catch((error) => console.log(error));
    }
  };

  let items = [
    {
      label: "Site Stats",
      key: "4",
      children: (
        <TabPanel>
          <SiteStats />
        </TabPanel>
      ),
    },
    {
      label: "Site",
      key: "1",
      children: (
        <TabPanel>
          <SiteForm
            initialValues={initialFormValues}
            edit
            onFinish={onFinishEditSite}
          />
        </TabPanel>
      ),
    },
    {
      label: "Alarm Configuration",
      key: "9",
      children: (
        <TabPanel>
          <SiteAlarmsConfig units={units} setUnits={setUnits} />
        </TabPanel>
      ),
    },
    {
      label: "Baseline",
      key: "2",
      children: (
        <TabPanel>
          <SiteBaseline />
        </TabPanel>
      ),
    },
    {
      label: "Download Data",
      key: "6",
      children: (
        <TabPanel>
          <DownloadRequest />
        </TabPanel>
      ),
    },
    {
      label: "Nodes",
      key: "8",
      children: (
        <TabPanel>
          <Nodes />
        </TabPanel>
      ),
    },
  ];

  const adminOnlyRoutes: string[] = ["Site", "Nodes"];

  if (!isAdmin) {
    items = items.filter((item) => !adminOnlyRoutes.includes(item.label));
  }

  useEffect(() => {
    if (currentOrg !== null && currentSite !== null) {
      dispatch(setPageTitle(`${currentOrg.name} - ${currentSite.name}`));
      if (isAdmin) {
        dispatch(setGoBackRoute(`/organization/${OrgId}/sites`));
      } else {
        dispatch(setGoBackRoute("/userSites"));
      }
    }
  });
  return <Tabs size="small" items={items} destroyInactiveTabPane />;
};

export default SingleNodeSite;
