import { FC, useState } from "react";
import styles from "../styles.module.scss";
import { Button, Input } from "antd";
import { useUpdateGpaqsAlarmIncidentMutation } from "../../../store/gpaqsAlarmIncidents/api";
import {
  AlarmEvent,
  GpaqsAlarmIncident,
} from "../../../components/MapController/types";
import { IncidentDetailsProps } from "../types";
import {
  AlarmIncidentEnum,
  ConcentrationAlarmIncident,
} from "../../../store/orgAlarmIncidentsV2/types";
import IncidentTypeSelection from "../../../components/MapController/AlarmDetails/IncidentTypeSelection/IncidentTypeSelection";

const { TextArea } = Input;

const IncidentDetails: FC<IncidentDetailsProps> = ({
  incident,
  refreshIncidents,
  onCancel,
}) => {
  const [updateGpaqsAlarmIncidentMutation] =
    useUpdateGpaqsAlarmIncidentMutation();
  const [incidentNotes, setIncidentNotes] = useState<string>(
    incident.incidentNotes != null ? incident.incidentNotes : ""
  );
  const [incidentType, setIncidentType] = useState(incident.incidentType);

  const onUpdateIncident = (): void => {
    if (incidentType === "" || incidentNotes === "") {
      window.alert("Missing Values");
      return;
    }
    const payload = {
      ...incident,
      incidentType,
      incidentNotes,
      status: "Classified",
    };
    updateGpaqsAlarmIncidentMutation(payload)
      .then(() => {
        window.alert("Incident Successfully Updated");
        refreshIncidents();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const LeakEvent = (props: {
    event: AlarmEvent;
    name: string;
  }): JSX.Element => {
    const { event, name } = props;

    return (
      <div>
        <p>
          <b>{name}</b>
        </p>
        <p>Avg Rate (MCFH): {event["avgRate(MCF/h)"].toFixed(3)}</p>
        <p>Coef Variance: {event.coefficientOfVariation}</p>
        <p>Emission (MCF): {event["emission(MCF)"].toFixed(3)}</p>
        <p>Position: {event.likelyLeakLocation}</p>
      </div>
    );
  };

  const HighPpm = (props: {
    incident: ConcentrationAlarmIncident;
    name: string;
  }): JSX.Element => {
    const { incident, name } = props;

    return (
      <div>
        <p>
          <b>{name}</b>
        </p>
        <p>Methane PPM: {Math.round(parseFloat(incident.ppm))}</p>
        <p>Node: {incident.NodeId}</p>
      </div>
    );
  };

  return (
    <div className={styles.CollapseWrapper}>
      {incident.type === AlarmIncidentEnum.GPAQS &&
        (incident as GpaqsAlarmIncident).most_likely_event !== undefined &&
        LeakEvent({
          event: (incident as GpaqsAlarmIncident).most_likely_event,
          name: "Most Likely Leak Event",
        })}
      {incident.type === AlarmIncidentEnum.GPAQS &&
        (incident as GpaqsAlarmIncident).most_likely_event_II !== undefined &&
        LeakEvent({
          event: (incident as GpaqsAlarmIncident).most_likely_event_II,
          name: "Second Most Likely Leak Event",
        })}
      {incident.type === AlarmIncidentEnum.CONCENTRATION &&
        HighPpm({
          incident: incident as ConcentrationAlarmIncident,
          name: "High Concentration Event",
        })}
      <div>
        <div>
          <b>Incident Notes</b>
        </div>
        <TextArea
          value={incidentNotes}
          onChange={(e) => setIncidentNotes(e.target.value)}
          style={{ marginTop: 10, marginBottom: 10 }}
        />
        <div>
          <b>Incident Type</b>
        </div>
        <IncidentTypeSelection
          incidentType={incidentType}
          onChange={(result) => setIncidentType(result)}
          style={{ marginTop: 10, width: 130 }}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <Button type="primary" onClick={onUpdateIncident}>
          Update
        </Button>
        <Button style={{ marginLeft: 10 }} onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default IncidentDetails;
