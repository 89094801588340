import { Tag, Tooltip, Input, Radio } from "antd";
import { ColumnsType } from "antd/lib/table";
import {
  ThunderboltOutlined,
  ThunderboltFilled,
  CheckOutlined,
  GlobalOutlined,
  LineChartOutlined,
  WifiOutlined,
} from "@ant-design/icons";
import { FC, useState, useEffect, ChangeEvent } from "react";
import { useAppDispatch } from "../../store/hooks";
import { setGoBackRoute, setPageTitle } from "../../store/ui/slice";
import { useGetCurrentUserQuery } from "../../store/auth/api";
import { useGetLatestStatusChecksQuery } from "../../store/statusChecks/api";
import { Node } from "../../store/nodes/types";
import { sitesApi } from "../../store/sites/api";
import { Site } from "../../store/sites/types";
import { NodeData, StatusCheckObj } from "../../store/statusChecks/types";
import { getHoursSinceStatusCheck } from "../../helpers/dayjsHelpers";
import { WindIcon, CompassIcon, FrequencyIcon } from "./icons";
import styles from "./styles.module.scss";
import { StatusCheckNamesMapType, FiltersType, DevicesProps } from "./types";
import { RadioChangeEvent } from "antd/lib";
import AvailableNodes from "../../components/Nodes/AvailableNodes/AvailableNodes";
import StatusChecks from "../../components/StatusChecks/StatusChecks";
import FirmwareConfig from "../../components/FirmwareConfig/FirmwareConfig";

export const iconColor = "#757474";

const statusCheckMap: StatusCheckNamesMapType = {
  online: "Online",
  validData: "Valid Data",
  validWindSpeed: "Wind Speed",
  validWindDirection: "Wind Dir",
  tier1Frequency: "T1 Frequency",
  tier2Frequency: "T2 Frequency",
  batteryVoltage: "Battery Voltage",
  signalQuality: "Signal Quality",
};

const filtersInitialState: FiltersType = {
  search: "",
  organization: "",
  showPassingNodes: false,
  showInactiveNodes: false,
};

const Devices: FC<DevicesProps> = ({ isTest }) => {
  const dispatch = useAppDispatch();
  const [selectedNodeId, setSelectedNodeId] = useState("");
  const [selectedSiteName, setSelectedSiteName] = useState<string | null>(null);
  const [showNodeInfo, setShowNodeInfo] = useState(false);
  const { data, isError, isLoading } = useGetLatestStatusChecksQuery(null);
  const [filteredDevices, setFilteredDevices] = useState<NodeData[]>([]);
  const [filters, setFilters] = useState(filtersInitialState);
  const hoursSinceStatusCheck = isTest ? 1 : getHoursSinceStatusCheck();
  const hoursSinceStatusCheckMessage = `${hoursSinceStatusCheck} Hour${
    Number(hoursSinceStatusCheck) > 1 ? "s" : ""
  } Since Last Status Check`;
  const [triggerGetSites] = sitesApi.useLazyGetSitesQuery();

  const { data: userData } = useGetCurrentUserQuery(null);
  let userEmail = "";
  if (userData !== undefined) {
    userEmail = userData.email;
  }

  let devices: NodeData[] = [];
  if (!isError && !isLoading && data !== undefined) {
    devices = data.result.map((ele, idx) => {
      return { ...ele, key: idx };
    });
  }

  const organizationNames: string[] = [];
  devices.forEach((device) => {
    if (Object.prototype.hasOwnProperty.call(device, "orgName")) {
      const name = device.orgName;
      if (name !== undefined) {
        if (!organizationNames.includes(name)) {
          organizationNames.push(name);
        }
      }
    }
  });

  const organizationOptions = organizationNames.map((name, idx) => {
    return {
      key: idx,
      label: name,
      value: name,
    };
  });

  const statusCheckColumns: ColumnsType<NodeData> = Object.entries(
    statusCheckMap
  ).map(([checkName, checkText]) => {
    const title = statusCheckMap[checkName as keyof StatusCheckNamesMapType];
    let icon = <CheckOutlined />;
    const iconStyle = { color: iconColor };
    const {
      online,
      validData,
      validWindSpeed,
      validWindDirection,
      tier1Frequency,
      tier2Frequency,
      batteryVoltage,
      signalQuality,
    } = statusCheckMap;
    switch (checkText) {
      case online:
        icon = <GlobalOutlined style={iconStyle} />;
        break;
      case validData:
        icon = <LineChartOutlined style={iconStyle} />;
        break;
      case validWindSpeed:
        icon = <WindIcon />;
        break;
      case validWindDirection:
        icon = <CompassIcon />;
        break;
      case tier1Frequency:
        icon = <FrequencyIcon />;
        break;
      case tier2Frequency:
        icon = <FrequencyIcon />;
        break;
      case batteryVoltage:
        icon = <ThunderboltOutlined style={iconStyle} />;
        break;
      case signalQuality:
        icon = <WifiOutlined style={iconStyle} />;
        break;
    }

    return {
      title,
      dataIndex: "statusCheck",
      key: `statusCheck-${checkName}`,
      render: (statusCheck: StatusCheckObj) => {
        if (statusCheck === undefined) {
          return "-";
        }
        const key = `${checkName}` as keyof StatusCheckObj;
        let percent;
        if (key === "online") {
          percent = statusCheck[key].result === "pass" ? "100%" : "FAIL";
        } else {
          percent = statusCheck[key].message.split(" ")[0];
        }

        if (percent === "No") {
          percent = "FAIL";
        }
        const color = statusCheck[key].result === "pass" ? "green" : "red";
        return (
          <div className={styles.StatusTagWrapper}>
            <Tag color={color} className={styles.StatusTag} icon={icon} />
            <p className={styles.StatusTagP}>{percent}</p>
          </div>
        );
      },
    };
  });

  const sortColumns = (a: string, b: string): number => {
    if (a === undefined || b === undefined) return 0;
    const aValue = a.toLowerCase();
    const bValue = b.toLowerCase();
    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  };

  const columns: ColumnsType<NodeData> = [
    {
      title: "MAC address",
      key: "NodeId",
      sorter: (a, b) => sortColumns(a.NodeId, b.NodeId),
      render: (node: Node) => {
        return (
          <a
            onClick={async () => await onClickNodeId(node)}
            className={styles.Clickable}
          >
            {node.NodeId}
          </a>
        );
      },
    },
    {
      title: "Node",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => sortColumns(a.name, b.name),
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      sorter: (a, b) => sortColumns(a.siteName ?? "", b.siteName ?? ""),
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (active: boolean) => {
        return active ? (
          <Tag color="green" className={styles.StatusTag}>
            <ThunderboltFilled />
          </Tag>
        ) : (
          <Tag color="red" className={styles.StatusTag}>
            <ThunderboltFilled />
          </Tag>
        );
      },
    },
    ...statusCheckColumns,
  ];

  const updateFilteredDevices = (filters: FiltersType): void => {
    const { search, organization, showPassingNodes, showInactiveNodes } =
      filters;
    setFilteredDevices(
      devices.filter((device) => {
        const siteName = device?.siteName ?? "";
        const siteId = device?.SiteId ?? "";
        const name = device?.name ?? "";
        const nodeId = device?.NodeId ?? "";
        const orgName = device?.orgName ?? "";
        const orgId = device?.OrgId ?? "";

        // search
        const includesSearch = (attr: string): boolean => {
          if (attr === undefined) return false;
          return attr.toLowerCase().includes(search);
        };
        const searchBool =
          includesSearch(siteName) ||
          includesSearch(siteId) ||
          includesSearch(name) ||
          includesSearch(nodeId) ||
          includesSearch(orgName) ||
          includesSearch(orgId);

        // organizations
        let organizationBool = true;
        if (organization !== "" && organization !== undefined) {
          if (Object.prototype.hasOwnProperty.call(device, "orgName")) {
            organizationBool =
              organization.toLowerCase() === orgName.toLowerCase();
          } else if (organization !== "" && organization !== undefined) {
            organizationBool = false;
          }
        }

        // passing nodes
        let passingBool = true;
        if (!showPassingNodes) {
          passingBool = false;
          const isPass =
            device?.result !== undefined ? device.result === "pass" : false;
          if (!isPass) passingBool = true;
        }

        // inactive nodes
        const isDeviceActive = device.active;
        const inactiveBool = isDeviceActive ? true : showInactiveNodes;

        return searchBool && organizationBool && inactiveBool && passingBool;
      })
    );
  };

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    const newSearch = event.target.value.toLowerCase();
    const newFilters = {
      ...filters,
      search: newSearch,
    };
    setFilters(newFilters);
    updateFilteredDevices(newFilters);
  };

  const onChangeOrg = (orgName: string): void => {
    const newOrg = orgName;
    const newFilters = {
      ...filters,
      organization: newOrg,
    };
    setFilters(newFilters);
    updateFilteredDevices(newFilters);
  };

  const onSwitchShowInactiveNodes = (isOn: boolean): void => {
    const newFilters = {
      ...filters,
      showInactiveNodes: isOn,
    };
    setFilters(newFilters);
    updateFilteredDevices(newFilters);
  };

  const onSwitchShowPassingNodes = (isOn: boolean): void => {
    const newFilters = {
      ...filters,
      showPassingNodes: isOn,
    };
    setFilters(newFilters);
    updateFilteredDevices(newFilters);
  };

  const onClickNodeId = async (node: Node): Promise<void> => {
    const { NodeId: nodeId, SiteId: siteId, OrgId: orgId } = node;
    const orgSites = (await triggerGetSites(orgId)).data;
    const site = orgSites?.find((site: Site) => site.SiteId === siteId);
    const siteName = site?.name ?? null;

    setSelectedNodeId(nodeId);
    setSelectedSiteName(siteName);
    setShowNodeInfo(true);
  };

  const onClickOkNodeInfoModal = (): void => {
    setShowNodeInfo(false);
  };

  const onClickCancelNodeInfoModal = (): void => {
    setShowNodeInfo(false);
  };

  useEffect(() => {
    dispatch(setPageTitle("Devices"));
    dispatch(setGoBackRoute(""));
  }, []);

  useEffect(() => {
    updateFilteredDevices(filters);
  }, [isLoading]);

  const STATUS_CHECKS = "STATUS_CHECKS";
  const AVAILABLE_NODES = "AVAILABLE_NODES";
  const MANAGE_FIRMWARE = "MANAGE_FIRMWARE";

  const [selection, setSelection] = useState(STATUS_CHECKS);
  const onChangeRadio = (event: RadioChangeEvent): void => {
    const newSelection: string = event.target.value as string;
    setSelection(newSelection);
  };

  let DeviceTab: JSX.Element | null = null;

  switch (selection) {
    case STATUS_CHECKS:
      DeviceTab = (
        <StatusChecks
          showNodeInfo={showNodeInfo}
          selectedNodeId={selectedNodeId}
          onClickCancelNodeInfoModal={onClickCancelNodeInfoModal}
          onClickOkNodeInfoModal={onClickOkNodeInfoModal}
          selectedSiteName={selectedSiteName}
          userEmail={userEmail}
          organizationOptions={organizationOptions}
          onChangeOrg={onChangeOrg}
          onSwitchShowPassingNodes={onSwitchShowPassingNodes}
          onSwitchShowInactiveNodes={onSwitchShowInactiveNodes}
          hoursSinceStatusCheckMessage={hoursSinceStatusCheckMessage}
          columns={columns}
          filteredDevices={isTest ? devices : filteredDevices}
          isLoading={isLoading}
        />
      );
      break;
    case AVAILABLE_NODES:
      DeviceTab = (
        <AvailableNodes searchInput={filters.search} showAddAction={false} />
      );
      break;
    case MANAGE_FIRMWARE:
      DeviceTab = <FirmwareConfig />;
  }

  return (
    <div>
      <div className={styles.Flex}>
        <div className={styles.ButtonWrapper}>
          <Radio.Group
            onChange={onChangeRadio}
            defaultValue={selection}
            size="middle"
          >
            <Radio.Button
              value={STATUS_CHECKS}
              checked={selection === STATUS_CHECKS}
            >
              Status Checks
            </Radio.Button>
            <Radio.Button
              value={AVAILABLE_NODES}
              checked={selection === AVAILABLE_NODES}
            >
              Available Nodes
            </Radio.Button>
            <Radio.Button
              value={MANAGE_FIRMWARE}
              checked={selection === MANAGE_FIRMWARE}
            >
              Manage Firmware
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
      {selection !== MANAGE_FIRMWARE ? (
        <div>
          <div className={styles.SearchDiv}>
            <Tooltip title="Search by site name/id, or node name/id">
              <Input
                placeholder="Search Device..."
                allowClear
                onChange={(event) => onChangeSearch(event)}
              />
            </Tooltip>
          </div>
        </div>
      ) : null}
      {DeviceTab}
    </div>
  );
};

export default Devices;
