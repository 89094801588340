const R = 6371000; // Radius of Earth in meters

export const haversine = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  function toRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
  }

  const lat1Rad = toRadians(lat1);
  const lon1Rad = toRadians(lon1);
  const lat2Rad = toRadians(lat2);
  const lon2Rad = toRadians(lon2);

  const dlat = lat2Rad - lat1Rad;
  const dlon = lon2Rad - lon1Rad;

  const a =
    Math.sin(dlat / 2) ** 2 +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dlon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance;
};

export const calculateLatAdjustment = (distanceMeters: number): number => {
  // Number of m per degree of latitude is approximately the same at all locations
  return (distanceMeters / R) * (180 / Math.PI);
};

export const calculateLongAdjustment = (
  distanceMeters: number,
  latitude: number
): number => {
  // Number of m per degree of longitude is approximately this adjusting for latitude
  const latitudeRad = (latitude * Math.PI) / 180;
  return (distanceMeters / (R * Math.cos(latitudeRad))) * (180 / Math.PI);
};

interface GridCell {
  isActive: boolean;
  height: string;
  equipment: string[];
  name: string;
}

export interface Grid {
  gridCellDimensionMeters: number;
  minLong: number;
  maxLong: number;
  minLat: number;
  maxLat: number;
  gridX: number;
  gridY: number;
  SiteId: string;
  Timestamp?: string;
  SetupId?: string;
  [key: string]:
  | boolean
  | number
  | string
  | Record<string, boolean | string>
  | GridCell
  | undefined;
}

export const calculateGridHelper = (
  siteId: string,
  minLat: number,
  minLong: number,
  maxLat: number,
  maxLong: number,
  gridDimensionInMeters: number
): Grid => {
  // Define borders of the grid
  const longDistance = haversine(minLat, minLong, minLat, maxLong);
  const latDistance = haversine(minLat, minLong, maxLat, minLong);
  const gridX = Math.ceil(longDistance / gridDimensionInMeters);
  const gridY = Math.ceil(latDistance / gridDimensionInMeters);

  const adjustedMaxLat =
    minLat + calculateLatAdjustment(gridY * gridDimensionInMeters);
  const adjustedMaxLong =
    minLong + calculateLongAdjustment(gridX * gridDimensionInMeters, minLat);

  const grid: Grid = {
    gridCellDimensionMeters: gridDimensionInMeters,
    minLong,
    maxLong: adjustedMaxLong,
    minLat,
    maxLat: adjustedMaxLat,
    gridX,
    gridY,
    SiteId: siteId,
  };

  for (let x = 1; x <= gridX; x++) {
    for (let y = 1; y <= gridY; y++) {
      // For now, assume height of each grid cell ~ 4 meters. This can be configurable per grid.
      grid[`(${x},${y})`] = { isActive: false, height: "4" };
    }
  }

  return grid;
};

export const defaultPointObject = {
  longitude: 0,
  latitude: 0,
};

export interface GridSizeOption {
  value: number;
  label: string;
}

export const defaultGridSizeOptions: GridSizeOption[] = [
  { value: 10, label: "10 meters" },
  { value: 15, label: "15 meters" },
  { value: 20, label: "20 meters" },
  { value: 30, label: "30 meters" },
];
