export const mockTableData = [
  {
    is_minimum_notification_threshold_met: true,
    alertRollingAverageConfig: 1,
    most_likely_event: {
      SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
      LowerErrorMcfh: "0.027606993745200113",
      "emission(MCF)": 0.193,
      ConfidenceScore: 17.646,
      gpaqsHits: 1,
      coefficientOfVariation: 0.2217807323604453,
      totalScore: 58.51696338197774,
      Timestamp: "2024-10-02 14:20:00",
      UpperErrorMcfh: "0.034211270661712916",
      likelyLeakLocation: "(7,3)",
      "avgRate(MCF/h)": 0.16512774463634736,
      "threshold(MCFH)": 0.044891030830027434,
      covPercentage: 77.82192676395547,
      ConfidencePercentage: 39.212,
      TotalChecks: 45,
    },
    SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
    mcfAlertHrThreshold: 0.044891030830027434,
    alarm_sent: true,
    alarmRollingAverageConfig: 1,
    mcfHrThreshold: 0.044891030830027434,
    recipients: ["fgomez@mangochango.com"],
    end_time: "2024-10-07 22:20:00",
    should_alarm: true,
    Timestamp: "2024-10-02 15:30:00",
    grid_timestamp: "2023-11-02 17:12:36",
    most_likely_event_II: {
      SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
      LowerErrorMcfh: "0.031218850378291196",
      "emission(MCF)": 0.129,
      ConfidenceScore: 18.122,
      gpaqsHits: 1,
      coefficientOfVariation: 0.47056262959183615,
      totalScore: 46.60786852040819,
      Timestamp: "2024-10-02 14:20:00",
      UpperErrorMcfh: "0.03834844625540681",
      likelyLeakLocation: "(9,2)",
      "avgRate(MCF/h)": 0.11068297557660811,
      "threshold(MCFH)": 0.044891030830027434,
      covPercentage: 52.94373704081639,
      ConfidencePercentage: 40.272,
      TotalChecks: 45,
    },
    follow_ups: [
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.032751116455398875",
          "emission(MCF)": 0.016,
          ConfidenceScore: 16.89,
          gpaqsHits: 1,
          coefficientOfVariation: 0.22782524399409532,
          totalScore: 57.37573780029523,
          Timestamp: "2024-10-07 21:00:00",
          UpperErrorMcfh: "0.06160880089791338",
          likelyLeakLocation: "(10,10)",
          "avgRate(MCF/h)": 0.09452840285570488,
          "threshold(MCFH)": 0.0569417912950171,
          covPercentage: 77.21747560059046,
          ConfidencePercentage: 37.534,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.034748575735800466",
          "emission(MCF)": 0.017,
          ConfidenceScore: 14.953,
          gpaqsHits: 1,
          coefficientOfVariation: 0.2278252439940953,
          totalScore: 55.22323780029523,
          Timestamp: "2024-10-07 21:00:00",
          UpperErrorMcfh: "0.06500718261652591",
          likelyLeakLocation: "(9,10)",
          "avgRate(MCF/h)": 0.10249349263798453,
          "threshold(MCFH)": 0.0569417912950171,
          covPercentage: 77.21747560059046,
          ConfidencePercentage: 33.229,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.0381775822579882",
          "emission(MCF)": 0.016,
          ConfidenceScore: 16.841,
          gpaqsHits: 1,
          coefficientOfVariation: 0.22782524399409532,
          totalScore: 57.32123780029523,
          Timestamp: "2024-10-07 21:00:00",
          UpperErrorMcfh: "0.07111394837088718",
          likelyLeakLocation: "(10,10)",
          "avgRate(MCF/h)": 0.09452840285570488,
          "threshold(MCFH)": 0.0569417912950171,
          covPercentage: 77.21747560059046,
          ConfidencePercentage: 37.425,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.03987055084425008",
          "emission(MCF)": 0.017,
          ConfidenceScore: 14.904,
          gpaqsHits: 1,
          coefficientOfVariation: 0.2278252439940953,
          totalScore: 55.169237800295235,
          Timestamp: "2024-10-07 21:00:00",
          UpperErrorMcfh: "0.07351513414218476",
          likelyLeakLocation: "(9,10)",
          "avgRate(MCF/h)": 0.10249349263798453,
          "threshold(MCFH)": 0.0569417912950171,
          covPercentage: 77.21747560059046,
          ConfidencePercentage: 33.121,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.03799252447012837",
          "emission(MCF)": 0.016,
          ConfidenceScore: 16.849,
          gpaqsHits: 1,
          coefficientOfVariation: 0.22782524399409532,
          totalScore: 57.33023780029523,
          Timestamp: "2024-10-07 21:00:00",
          UpperErrorMcfh: "0.07077706934441634",
          likelyLeakLocation: "(10,10)",
          "avgRate(MCF/h)": 0.09452840285570488,
          "threshold(MCFH)": 0.0569417912950171,
          covPercentage: 77.21747560059046,
          ConfidencePercentage: 37.443,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.0401415389920244",
          "emission(MCF)": 0.017,
          ConfidenceScore: 14.912,
          gpaqsHits: 1,
          coefficientOfVariation: 0.2278252439940953,
          totalScore: 55.17773780029523,
          Timestamp: "2024-10-07 21:00:00",
          UpperErrorMcfh: "0.07415871768452062",
          likelyLeakLocation: "(9,10)",
          "avgRate(MCF/h)": 0.10249349263798453,
          "threshold(MCFH)": 0.0569417912950171,
          covPercentage: 77.21747560059046,
          ConfidencePercentage: 33.138,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.03810053342936983",
          "emission(MCF)": 0.016,
          ConfidenceScore: 18.022,
          gpaqsHits: 1,
          coefficientOfVariation: 0.22782524399409532,
          totalScore: 58.633237800295234,
          Timestamp: "2024-10-07 21:00:00",
          UpperErrorMcfh: "0.07106267292303572",
          likelyLeakLocation: "(10,10)",
          "avgRate(MCF/h)": 0.09452840285570488,
          "threshold(MCFH)": 0.0569417912950171,
          covPercentage: 77.21747560059046,
          ConfidencePercentage: 40.049,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.039992589020071696",
          "emission(MCF)": 0.017,
          ConfidenceScore: 16.981,
          gpaqsHits: 1,
          coefficientOfVariation: 0.2278252439940953,
          totalScore: 57.476737800295226,
          Timestamp: "2024-10-07 21:00:00",
          UpperErrorMcfh: "0.0738726145390071",
          likelyLeakLocation: "(9,10)",
          "avgRate(MCF/h)": 0.10249349263798453,
          "threshold(MCFH)": 0.0569417912950171,
          covPercentage: 77.21747560059046,
          ConfidencePercentage: 37.736,
          TotalChecks: 45,
        },
      },
    ],
    type: "gpaqsAlarm",
    key: 0,
  },
  {
    is_minimum_notification_threshold_met: true,
    alertRollingAverageConfig: 1,
    most_likely_event: {
      SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
      LowerErrorMcfh: "0.011861941157082966",
      "emission(MCF)": 0.011,
      ConfidenceScore: 20.544,
      gpaqsHits: 1,
      coefficientOfVariation: 0.3085390754792437,
      totalScore: 57.39954622603781,
      Timestamp: "2024-10-04 04:20:00",
      UpperErrorMcfh: "0.019387536960094088",
      likelyLeakLocation: "(6,2)",
      "avgRate(MCF/h)": 0.06554931645805062,
      "threshold(MCFH)": 0.0480366899457688,
      covPercentage: 69.14609245207564,
      ConfidencePercentage: 45.653,
      TotalChecks: 45,
    },
    SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
    mcfAlertHrThreshold: 0.0480366899457688,
    alarm_sent: true,
    alarmRollingAverageConfig: 1,
    mcfHrThreshold: 0.0480366899457688,
    recipients: ["fgomez@mangochango.com"],
    end_time: "2024-10-04 18:50:00",
    should_alarm: true,
    Timestamp: "2024-10-04 04:50:00",
    grid_timestamp: "2023-11-02 17:12:36",
    most_likely_event_II: {
      SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
      LowerErrorMcfh: "0.012857219695377542",
      "emission(MCF)": 0.012,
      ConfidenceScore: 20.452,
      gpaqsHits: 1,
      coefficientOfVariation: 0.3085390754792437,
      totalScore: 57.29804622603782,
      Timestamp: "2024-10-04 04:20:00",
      UpperErrorMcfh: "0.021098483341475096",
      likelyLeakLocation: "(6,1)",
      "avgRate(MCF/h)": 0.0729243620232427,
      "threshold(MCFH)": 0.0480366899457688,
      covPercentage: 69.14609245207564,
      ConfidencePercentage: 45.45,
      TotalChecks: 45,
    },
    follow_ups: [
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.017084500566825873",
          "emission(MCF)": 0.011,
          ConfidenceScore: 19.568,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3085390754792437,
          totalScore: 56.31504622603782,
          Timestamp: "2024-10-04 04:20:00",
          UpperErrorMcfh: "0.027880356502129618",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.06554931645805062,
          "threshold(MCFH)": 0.0480366899457688,
          covPercentage: 69.14609245207564,
          ConfidencePercentage: 43.484,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.016907819382676114",
          "emission(MCF)": 0.012,
          ConfidenceScore: 19.476,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3085390754792437,
          totalScore: 56.21354622603782,
          Timestamp: "2024-10-04 04:20:00",
          UpperErrorMcfh: "0.02763644338998002",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.0729243620232427,
          "threshold(MCFH)": 0.0480366899457688,
          covPercentage: 69.14609245207564,
          ConfidencePercentage: 43.281,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.0201146933279372",
          "emission(MCF)": 0.011,
          ConfidenceScore: 20.578,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3085390754792437,
          totalScore: 57.43704622603782,
          Timestamp: "2024-10-04 04:20:00",
          UpperErrorMcfh: "0.033216849394897476",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.06554931645805062,
          "threshold(MCFH)": 0.0480366899457688,
          covPercentage: 69.14609245207564,
          ConfidencePercentage: 45.728,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.019307006030552624",
          "emission(MCF)": 0.012,
          ConfidenceScore: 20.486,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3085390754792437,
          totalScore: 57.33554622603782,
          Timestamp: "2024-10-04 04:20:00",
          UpperErrorMcfh: "0.03146702121020393",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.0729243620232427,
          "threshold(MCFH)": 0.0480366899457688,
          covPercentage: 69.14609245207564,
          ConfidencePercentage: 45.525,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02132001532306022",
          "emission(MCF)": 0.011,
          ConfidenceScore: 20.58,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3085390754792437,
          totalScore: 57.43954622603782,
          Timestamp: "2024-10-04 04:20:00",
          UpperErrorMcfh: "0.034590158346976006",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.06554931645805062,
          "threshold(MCFH)": 0.0480366899457688,
          covPercentage: 69.14609245207564,
          ConfidencePercentage: 45.733,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.020045912409729673",
          "emission(MCF)": 0.012,
          ConfidenceScore: 20.488,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3085390754792437,
          totalScore: 57.33804622603782,
          Timestamp: "2024-10-04 04:20:00",
          UpperErrorMcfh: "0.03264792509690784",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.0729243620232427,
          "threshold(MCFH)": 0.0480366899457688,
          covPercentage: 69.14609245207564,
          ConfidencePercentage: 45.53,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02048777713538862",
          "emission(MCF)": 0.011,
          ConfidenceScore: 21.58,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3085390754792437,
          totalScore: 58.55054622603782,
          Timestamp: "2024-10-04 04:20:00",
          UpperErrorMcfh: "0.0333849001493029",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.06554931645805062,
          "threshold(MCFH)": 0.0480366899457688,
          covPercentage: 69.14609245207564,
          ConfidencePercentage: 47.955,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.019350729442056437",
          "emission(MCF)": 0.012,
          ConfidenceScore: 21.488,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3085390754792437,
          totalScore: 58.44904622603782,
          Timestamp: "2024-10-04 04:20:00",
          UpperErrorMcfh: "0.03187507644214727",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.0729243620232427,
          "threshold(MCFH)": 0.0480366899457688,
          covPercentage: 69.14609245207564,
          ConfidencePercentage: 47.752,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.007688283462399411",
          "emission(MCF)": 0.021,
          ConfidenceScore: 19.584,
          gpaqsHits: 1,
          coefficientOfVariation: 0.1517676117926336,
          totalScore: 64.17211941036831,
          Timestamp: "2024-10-04 07:20:00",
          UpperErrorMcfh: "0.01250433737020477",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.06283233683076848,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 84.82323882073663,
          ConfidencePercentage: 43.521,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.00862826746260645",
          "emission(MCF)": 0.023,
          ConfidenceScore: 19.493,
          gpaqsHits: 1,
          coefficientOfVariation: 0.15242206165358288,
          totalScore: 64.03789691732086,
          Timestamp: "2024-10-04 07:20:00",
          UpperErrorMcfh: "0.014384605310456357",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.06995023525668295,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 84.75779383464172,
          ConfidencePercentage: 43.318,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.010288588387792447",
          "emission(MCF)": 0.021,
          ConfidenceScore: 19.588,
          gpaqsHits: 1,
          coefficientOfVariation: 0.1517676117926336,
          totalScore: 64.17661941036832,
          Timestamp: "2024-10-04 07:20:00",
          UpperErrorMcfh: "0.016774899322873953",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.06283233683076848,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 84.82323882073663,
          ConfidencePercentage: 43.53,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01155638524165188",
          "emission(MCF)": 0.023,
          ConfidenceScore: 19.497,
          gpaqsHits: 1,
          coefficientOfVariation: 0.15242206165358288,
          totalScore: 64.04239691732086,
          Timestamp: "2024-10-04 07:20:00",
          UpperErrorMcfh: "0.019006124168444466",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.06995023525668295,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 84.75779383464172,
          ConfidencePercentage: 43.327,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.009541166247997857",
          "emission(MCF)": 0.021,
          ConfidenceScore: 19.585,
          gpaqsHits: 1,
          coefficientOfVariation: 0.1517676117926336,
          totalScore: 64.17311941036832,
          Timestamp: "2024-10-04 07:20:00",
          UpperErrorMcfh: "0.015630685628899733",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.06283233683076848,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 84.82323882073663,
          ConfidencePercentage: 43.523,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.010645643023970844",
          "emission(MCF)": 0.023,
          ConfidenceScore: 19.494,
          gpaqsHits: 1,
          coefficientOfVariation: 0.15242206165358288,
          totalScore: 64.03889691732086,
          Timestamp: "2024-10-04 07:20:00",
          UpperErrorMcfh: "0.017455214748929433",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.06995023525668295,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 84.75779383464172,
          ConfidencePercentage: 43.32,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.010423608042237782",
          "emission(MCF)": 0.021,
          ConfidenceScore: 18.582,
          gpaqsHits: 1,
          coefficientOfVariation: 0.1517676117926336,
          totalScore: 63.058619410368316,
          Timestamp: "2024-10-04 07:20:00",
          UpperErrorMcfh: "0.017006872154878474",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.06283233683076848,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 84.82323882073663,
          ConfidencePercentage: 41.294,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.011627208614211256",
          "emission(MCF)": 0.023,
          ConfidenceScore: 18.491,
          gpaqsHits: 1,
          coefficientOfVariation: 0.15242206165358288,
          totalScore: 62.92439691732086,
          Timestamp: "2024-10-04 07:20:00",
          UpperErrorMcfh: "0.018973992660341422",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.06995023525668295,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 84.75779383464172,
          ConfidencePercentage: 41.091,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.011712556818867714",
          "emission(MCF)": 0.021,
          ConfidenceScore: 20.55,
          gpaqsHits: 1,
          coefficientOfVariation: 0.1517676117926336,
          totalScore: 65.24561941036832,
          Timestamp: "2024-10-04 07:20:00",
          UpperErrorMcfh: "0.01913616388804688",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.06283233683076848,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 84.82323882073663,
          ConfidencePercentage: 45.668,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.013065587791038464",
          "emission(MCF)": 0.023,
          ConfidenceScore: 20.459,
          gpaqsHits: 1,
          coefficientOfVariation: 0.15242206165358288,
          totalScore: 65.11089691732086,
          Timestamp: "2024-10-04 07:20:00",
          UpperErrorMcfh: "0.02146795170063793",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.06995023525668295,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 84.75779383464172,
          ConfidencePercentage: 45.464,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.010140924846732081",
          "emission(MCF)": 0.021,
          ConfidenceScore: 20.531,
          gpaqsHits: 1,
          coefficientOfVariation: 0.1517676117926336,
          totalScore: 65.22411941036832,
          Timestamp: "2024-10-04 07:20:00",
          UpperErrorMcfh: "0.016455852813941938",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.06283233683076848,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 84.82323882073663,
          ConfidencePercentage: 45.625,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.011376368576683464",
          "emission(MCF)": 0.023,
          ConfidenceScore: 20.44,
          gpaqsHits: 1,
          coefficientOfVariation: 0.15242206165358288,
          totalScore: 65.08989691732086,
          Timestamp: "2024-10-04 07:20:00",
          UpperErrorMcfh: "0.018494925624063803",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.06995023525668295,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 84.75779383464172,
          ConfidencePercentage: 45.422,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.008728742360787047",
          "emission(MCF)": 0.012,
          ConfidenceScore: 20.687,
          gpaqsHits: 1,
          coefficientOfVariation: 1,
          totalScore: 22.9855,
          Timestamp: "2024-10-04 08:50:00",
          UpperErrorMcfh: "0.015474299101334596",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07018601763074284,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 0,
          ConfidencePercentage: 45.971,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.00842560265451521",
          "emission(MCF)": 0.012,
          ConfidenceScore: 20.592,
          gpaqsHits: 1,
          coefficientOfVariation: 1,
          totalScore: 22.88,
          Timestamp: "2024-10-04 08:50:00",
          UpperErrorMcfh: "0.014545015517654039",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.07173882928262935,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 0,
          ConfidencePercentage: 45.76,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.012264896240525163",
          "emission(MCF)": 0.013,
          ConfidenceScore: 21.695,
          gpaqsHits: 1,
          coefficientOfVariation: 0.10347002248328142,
          totalScore: 68.93199887583593,
          Timestamp: "2024-10-04 08:50:00",
          UpperErrorMcfh: "0.020577375038499556",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07572649797739817,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 89.65299775167186,
          ConfidencePercentage: 48.211,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.012330806788176886",
          "emission(MCF)": 0.013,
          ConfidenceScore: 21.6,
          gpaqsHits: 1,
          coefficientOfVariation: 0.10347002248328127,
          totalScore: 68.82599887583594,
          Timestamp: "2024-10-04 08:50:00",
          UpperErrorMcfh: "0.0207990346371221",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.07740188849512941,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 89.65299775167188,
          ConfidencePercentage: 47.999,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.020541862666353414",
          "emission(MCF)": 0.013,
          ConfidenceScore: 21.707,
          gpaqsHits: 1,
          coefficientOfVariation: 0.10347002248328142,
          totalScore: 68.94499887583594,
          Timestamp: "2024-10-04 08:50:00",
          UpperErrorMcfh: "0.034354811387191604",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07572649797739817,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 89.65299775167186,
          ConfidencePercentage: 48.237,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.019545722237198117",
          "emission(MCF)": 0.012,
          ConfidenceScore: 21.585,
          gpaqsHits: 1,
          coefficientOfVariation: 0.10347002248328113,
          totalScore: 68.80999887583593,
          Timestamp: "2024-10-04 08:50:00",
          UpperErrorMcfh: "0.03234786877922832",
          likelyLeakLocation: "(10,2)",
          "avgRate(MCF/h)": 0.07410339557369497,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 89.65299775167188,
          ConfidencePercentage: 47.967,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.026384254126719034",
          "emission(MCF)": 0.013,
          ConfidenceScore: 18.707,
          gpaqsHits: 1,
          coefficientOfVariation: 0.10347002248328142,
          totalScore: 65.61199887583592,
          Timestamp: "2024-10-04 08:50:00",
          UpperErrorMcfh: "0.04372216824729901",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07572649797739817,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 89.65299775167186,
          ConfidencePercentage: 41.571,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.025492948352723182",
          "emission(MCF)": 0.012,
          ConfidenceScore: 18.585,
          gpaqsHits: 1,
          coefficientOfVariation: 0.10347002248328113,
          totalScore: 65.47649887583594,
          Timestamp: "2024-10-04 08:50:00",
          UpperErrorMcfh: "0.04221469031640115",
          likelyLeakLocation: "(10,2)",
          "avgRate(MCF/h)": 0.07410339557369497,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 89.65299775167188,
          ConfidencePercentage: 41.3,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.022341728543443146",
          "emission(MCF)": 0.038,
          ConfidenceScore: 19.667,
          gpaqsHits: 1,
          coefficientOfVariation: 0.10347002248328142,
          totalScore: 66.67849887583593,
          Timestamp: "2024-10-04 09:20:00",
          UpperErrorMcfh: "0.037088132293442475",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07572649797739817,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 89.65299775167186,
          ConfidencePercentage: 43.704,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.021417061078450674",
          "emission(MCF)": 0.037,
          ConfidenceScore: 19.545,
          gpaqsHits: 1,
          coefficientOfVariation: 0.10347002248328113,
          totalScore: 66.54299887583593,
          Timestamp: "2024-10-04 09:20:00",
          UpperErrorMcfh: "0.03532741922298162",
          likelyLeakLocation: "(10,2)",
          "avgRate(MCF/h)": 0.07410339557369497,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 89.65299775167188,
          ConfidencePercentage: 43.433,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02128142696593983",
          "emission(MCF)": 0.038,
          ConfidenceScore: 19.648,
          gpaqsHits: 1,
          coefficientOfVariation: 0.10347002248328142,
          totalScore: 66.65749887583593,
          Timestamp: "2024-10-04 09:20:00",
          UpperErrorMcfh: "0.035397831009693355",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07572649797739817,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 89.65299775167186,
          ConfidencePercentage: 43.662,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.021964884858979877",
          "emission(MCF)": 0.037,
          ConfidenceScore: 19.526,
          gpaqsHits: 1,
          coefficientOfVariation: 0.10347002248328113,
          totalScore: 66.52199887583593,
          Timestamp: "2024-10-04 09:20:00",
          UpperErrorMcfh: "0.03565232950284292",
          likelyLeakLocation: "(10,2)",
          "avgRate(MCF/h)": 0.07410339557369497,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 89.65299775167188,
          ConfidencePercentage: 43.391,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.023004066186545474",
          "emission(MCF)": 0.038,
          ConfidenceScore: 19.626,
          gpaqsHits: 1,
          coefficientOfVariation: 0.10347002248328142,
          totalScore: 66.63299887583594,
          Timestamp: "2024-10-04 09:20:00",
          UpperErrorMcfh: "0.03920949423065211",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07572649797739817,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 89.65299775167186,
          ConfidencePercentage: 43.613,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02573409184964337",
          "emission(MCF)": 0.037,
          ConfidenceScore: 19.504,
          gpaqsHits: 1,
          coefficientOfVariation: 0.10347002248328113,
          totalScore: 66.49749887583593,
          Timestamp: "2024-10-04 09:20:00",
          UpperErrorMcfh: "0.041687183179064516",
          likelyLeakLocation: "(10,2)",
          "avgRate(MCF/h)": 0.07410339557369497,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 89.65299775167188,
          ConfidencePercentage: 43.342,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.019217084298248432",
          "emission(MCF)": 0.022,
          ConfidenceScore: 19.523,
          gpaqsHits: 1,
          coefficientOfVariation: 0.33718340575783934,
          totalScore: 54.832329712108034,
          Timestamp: "2024-10-04 09:30:00",
          UpperErrorMcfh: "0.03183962431098698",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.06707307237793467,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 66.28165942421606,
          ConfidencePercentage: 43.383,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02057595605698893",
          "emission(MCF)": 0.027,
          ConfidenceScore: 19.501,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3371834057578393,
          totalScore: 54.80882971210803,
          Timestamp: "2024-10-04 09:30:00",
          UpperErrorMcfh: "0.034423698088809976",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.08012039101543485,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 66.28165942421606,
          ConfidencePercentage: 43.336,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.008730856742498752",
          "emission(MCF)": 0.01,
          ConfidenceScore: 22.525,
          gpaqsHits: 1,
          coefficientOfVariation: 0.07246611055151325,
          totalScore: 71.40469447242434,
          Timestamp: "2024-10-04 12:20:00",
          UpperErrorMcfh: "0.016336843207024887",
          likelyLeakLocation: "(1,4)",
          "avgRate(MCF/h)": 0.06046789428376899,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 92.75338894484868,
          ConfidencePercentage: 50.056,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.007700522746311124",
          "emission(MCF)": 0.009,
          ConfidenceScore: 22.997,
          gpaqsHits: 1,
          coefficientOfVariation: 1,
          totalScore: 25.5525,
          Timestamp: "2024-10-04 12:20:00",
          UpperErrorMcfh: "0.014363069768266783",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.05613661503810216,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 0,
          ConfidencePercentage: 51.105,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.008582633550507418",
          "emission(MCF)": 0.009,
          ConfidenceScore: 23.595,
          gpaqsHits: 1,
          coefficientOfVariation: 0.09371303896343115,
          totalScore: 71.53084805182844,
          Timestamp: "2024-10-04 12:20:00",
          UpperErrorMcfh: "0.015977817493492988",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.05585704586806153,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 90.62869610365688,
          ConfidencePercentage: 52.433,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.008575341074455192",
          "emission(MCF)": 0.01,
          ConfidenceScore: 22.525,
          gpaqsHits: 1,
          coefficientOfVariation: 0.071312678881047,
          totalScore: 71.46236605594765,
          Timestamp: "2024-10-04 12:20:00",
          UpperErrorMcfh: "0.01598339902027097",
          likelyLeakLocation: "(4,2)",
          "avgRate(MCF/h)": 0.060846808591280804,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 92.8687321118953,
          ConfidencePercentage: 50.056,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.011264138010486917",
          "emission(MCF)": 0.009,
          ConfidenceScore: 21.483,
          gpaqsHits: 1,
          coefficientOfVariation: 0.09371303896343115,
          totalScore: 69.18434805182844,
          Timestamp: "2024-10-04 12:20:00",
          UpperErrorMcfh: "0.02122345872599311",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.05585704586806153,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 90.62869610365688,
          ConfidencePercentage: 47.74,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.011608161468300031",
          "emission(MCF)": 0.01,
          ConfidenceScore: 20.413,
          gpaqsHits: 1,
          coefficientOfVariation: 0.071312678881047,
          totalScore: 69.11586605594765,
          Timestamp: "2024-10-04 12:20:00",
          UpperErrorMcfh: "0.021739424937938595",
          likelyLeakLocation: "(4,2)",
          "avgRate(MCF/h)": 0.060846808591280804,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 92.8687321118953,
          ConfidencePercentage: 45.363,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.013637741543541488",
          "emission(MCF)": 0.009,
          ConfidenceScore: 20.446,
          gpaqsHits: 1,
          coefficientOfVariation: 0.09371303896343115,
          totalScore: 68.03184805182843,
          Timestamp: "2024-10-04 12:20:00",
          UpperErrorMcfh: "0.025803480946264473",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.05585704586806153,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 90.62869610365688,
          ConfidencePercentage: 45.435,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.014416956254202367",
          "emission(MCF)": 0.01,
          ConfidenceScore: 19.376,
          gpaqsHits: 1,
          coefficientOfVariation: 0.071312678881047,
          totalScore: 67.96386605594765,
          Timestamp: "2024-10-04 12:20:00",
          UpperErrorMcfh: "0.027089054477104794",
          likelyLeakLocation: "(4,2)",
          "avgRate(MCF/h)": 0.060846808591280804,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 92.8687321118953,
          ConfidencePercentage: 43.059,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01573316423191948",
          "emission(MCF)": 0.009,
          ConfidenceScore: 19.499,
          gpaqsHits: 1,
          coefficientOfVariation: 0.09371303896343115,
          totalScore: 66.97984805182844,
          Timestamp: "2024-10-04 12:20:00",
          UpperErrorMcfh: "0.028887725006891917",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.05585704586806153,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 90.62869610365688,
          ConfidencePercentage: 43.331,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.016891923002894316",
          "emission(MCF)": 0.01,
          ConfidenceScore: 18.429,
          gpaqsHits: 1,
          coefficientOfVariation: 0.071312678881047,
          totalScore: 66.91136605594765,
          Timestamp: "2024-10-04 12:20:00",
          UpperErrorMcfh: "0.030543741786041637",
          likelyLeakLocation: "(4,2)",
          "avgRate(MCF/h)": 0.060846808591280804,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 92.8687321118953,
          ConfidencePercentage: 40.954,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.012804768183395397",
          "emission(MCF)": 0.035,
          ConfidenceScore: 15.576,
          gpaqsHits: 1,
          coefficientOfVariation: 0.012676885142355817,
          totalScore: 66.67315574288222,
          Timestamp: "2024-10-04 12:40:00",
          UpperErrorMcfh: "0.023316202119897808",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.05262741924858642,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 98.73231148576443,
          ConfidencePercentage: 34.614,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.017699921165836754",
          "emission(MCF)": 0.044,
          ConfidenceScore: 15.208,
          gpaqsHits: 1,
          coefficientOfVariation: 0.13251632554880968,
          totalScore: 60.271683722559516,
          Timestamp: "2024-10-04 12:40:00",
          UpperErrorMcfh: "0.03014464120729012",
          likelyLeakLocation: "(7,11)",
          "avgRate(MCF/h)": 0.06561476055285212,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 86.74836744511903,
          ConfidencePercentage: 33.795,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01762624321353402",
          "emission(MCF)": 0.036,
          ConfidenceScore: 17.412,
          gpaqsHits: 1,
          coefficientOfVariation: 0.02600176797792639,
          totalScore: 68.04641160110367,
          Timestamp: "2024-10-04 12:40:00",
          UpperErrorMcfh: "0.032434648221133025",
          likelyLeakLocation: "(5,2)",
          "avgRate(MCF/h)": 0.054121607984538155,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 97.39982320220736,
          ConfidencePercentage: 38.693,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01816467678987032",
          "emission(MCF)": 0.037,
          ConfidenceScore: 17.333,
          gpaqsHits: 1,
          coefficientOfVariation: 0.10667782029480398,
          totalScore: 63.9251089852598,
          Timestamp: "2024-10-04 12:40:00",
          UpperErrorMcfh: "0.03332739073079325",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.05607004216867578,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 89.3322179705196,
          ConfidencePercentage: 38.518,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.017859178348244163",
          "emission(MCF)": 0.027,
          ConfidenceScore: 20.229,
          gpaqsHits: 1,
          coefficientOfVariation: 0.02600176797792639,
          totalScore: 71.17641160110368,
          Timestamp: "2024-10-04 12:50:00",
          UpperErrorMcfh: "0.03078350605430667",
          likelyLeakLocation: "(5,2)",
          "avgRate(MCF/h)": 0.054121607984538155,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 97.39982320220736,
          ConfidencePercentage: 44.953,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.017328771891757456",
          "emission(MCF)": 0.029,
          ConfidenceScore: 20.287,
          gpaqsHits: 1,
          coefficientOfVariation: 0.03181872379128761,
          totalScore: 70.94956381043562,
          Timestamp: "2024-10-04 12:50:00",
          UpperErrorMcfh: "0.029854333343388693",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.05726661148297399,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 96.81812762087124,
          ConfidencePercentage: 45.081,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.015362133026617531",
          "emission(MCF)": 0.018,
          ConfidenceScore: 22.669,
          gpaqsHits: 1,
          coefficientOfVariation: 0.021428326553851518,
          totalScore: 74.11658367230743,
          Timestamp: "2024-10-04 13:00:00",
          UpperErrorMcfh: "0.024099010054821235",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.052711322661782675,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 97.85716734461485,
          ConfidencePercentage: 50.376,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.015896945651256992",
          "emission(MCF)": 0.019,
          ConfidenceScore: 22.39,
          gpaqsHits: 1,
          coefficientOfVariation: 0.03181872379128761,
          totalScore: 73.28656381043562,
          Timestamp: "2024-10-04 13:00:00",
          UpperErrorMcfh: "0.02546307730007176",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.05726661148297399,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 96.81812762087124,
          ConfidencePercentage: 49.755,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01615683271352348",
          "emission(MCF)": 0.01,
          ConfidenceScore: 22.62,
          gpaqsHits: 1,
          coefficientOfVariation: 0.03181872379128761,
          totalScore: 73.54206381043562,
          Timestamp: "2024-10-04 13:10:00",
          UpperErrorMcfh: "0.024350803412828366",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.05726661148297399,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 96.81812762087124,
          ConfidencePercentage: 50.266,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.017412974543968826",
          "emission(MCF)": 0.01,
          ConfidenceScore: 22.484,
          gpaqsHits: 1,
          coefficientOfVariation: 0.07253301367034906,
          totalScore: 71.35584931648255,
          Timestamp: "2024-10-04 13:10:00",
          UpperErrorMcfh: "0.026116790867657096",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.05820290345526702,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 92.74669863296509,
          ConfidencePercentage: 49.965,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01622370081707745",
          "emission(MCF)": 0.057,
          ConfidenceScore: 21.78,
          gpaqsHits: 1,
          coefficientOfVariation: 0.0287039490596337,
          totalScore: 72.76480254701832,
          Timestamp: "2024-10-04 13:30:00",
          UpperErrorMcfh: "0.02404882355007435",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.05669273388922074,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 97.12960509403663,
          ConfidencePercentage: 48.4,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.014598935588590196",
          "emission(MCF)": 0.053,
          ConfidenceScore: 21.722,
          gpaqsHits: 1,
          coefficientOfVariation: 0.05019536362785647,
          totalScore: 71.62573181860718,
          Timestamp: "2024-10-04 13:30:00",
          UpperErrorMcfh: "0.02216057620970682",
          likelyLeakLocation: "(5,2)",
          "avgRate(MCF/h)": 0.0532274613590613,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 94.98046363721436,
          ConfidencePercentage: 48.271,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.016762807204745978",
          "emission(MCF)": 0.066,
          ConfidenceScore: 20.66,
          gpaqsHits: 1,
          coefficientOfVariation: 0.024817773194015093,
          totalScore: 71.71511134029925,
          Timestamp: "2024-10-04 13:30:00",
          UpperErrorMcfh: "0.024065617961080317",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.05693305515342896,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 97.51822268059848,
          ConfidencePercentage: 45.912,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01786719064565991",
          "emission(MCF)": 0.074,
          ConfidenceScore: 21.028,
          gpaqsHits: 1,
          coefficientOfVariation: 0.2086030093113002,
          totalScore: 62.933849534434984,
          Timestamp: "2024-10-04 13:30:00",
          UpperErrorMcfh: "0.02575362882416229",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.06380931702072455,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 79.13969906886997,
          ConfidencePercentage: 46.728,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.015570486484168805",
          "emission(MCF)": 0.067,
          ConfidenceScore: 21.843,
          gpaqsHits: 1,
          coefficientOfVariation: 0.025679212512232718,
          totalScore: 72.98603937438837,
          Timestamp: "2024-10-04 13:40:00",
          UpperErrorMcfh: "0.01978521250004412",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.05729846820229351,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 97.43207874877673,
          ConfidencePercentage: 48.54,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01420507821417908",
          "emission(MCF)": 0.07,
          ConfidenceScore: 21.428,
          gpaqsHits: 1,
          coefficientOfVariation: 0.1452971738871501,
          totalScore: 66.5446413056425,
          Timestamp: "2024-10-04 13:40:00",
          UpperErrorMcfh: "0.018030479958052358",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.05961430368256023,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 85.47028261128499,
          ConfidencePercentage: 47.619,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.015191440174515587",
          "emission(MCF)": 0.067,
          ConfidenceScore: 22.548,
          gpaqsHits: 1,
          coefficientOfVariation: 0.02477875820219684,
          totalScore: 73.81456208989016,
          Timestamp: "2024-10-04 13:50:00",
          UpperErrorMcfh: "0.019126918308586253",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.057245294301554374,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 97.5221241797803,
          ConfidencePercentage: 50.107,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01420718769082712",
          "emission(MCF)": 0.073,
          ConfidenceScore: 22.535,
          gpaqsHits: 1,
          coefficientOfVariation: 0.07506126638847499,
          totalScore: 71.28543668057625,
          Timestamp: "2024-10-04 13:50:00",
          UpperErrorMcfh: "0.01787080360000039",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.06259205677829979,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 92.4938733611525,
          ConfidencePercentage: 50.077,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01331113014198965",
          "emission(MCF)": 0.088,
          ConfidenceScore: 21.163,
          gpaqsHits: 1,
          coefficientOfVariation: 0.25746904675730997,
          totalScore: 60.640547662134495,
          Timestamp: "2024-10-04 14:00:00",
          UpperErrorMcfh: "0.01686381008549096",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.07539913457044219,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 74.253095324269,
          ConfidencePercentage: 47.028,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.011619198412405057",
          "emission(MCF)": 0.087,
          ConfidenceScore: 21.372,
          gpaqsHits: 1,
          coefficientOfVariation: 0.2971981038550097,
          totalScore: 58.88659480724951,
          Timestamp: "2024-10-04 14:00:00",
          UpperErrorMcfh: "0.014626920529166573",
          likelyLeakLocation: "(11,2)",
          "avgRate(MCF/h)": 0.07431191220591242,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 70.28018961449902,
          ConfidencePercentage: 47.493,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.011924590842888197",
          "emission(MCF)": 0.075,
          ConfidenceScore: 20.637,
          gpaqsHits: 1,
          coefficientOfVariation: 0.0077683050054287404,
          totalScore: 72.54208474972856,
          Timestamp: "2024-10-04 14:10:00",
          UpperErrorMcfh: "0.01507047551834699",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.06419291558612755,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 99.22316949945713,
          ConfidencePercentage: 45.861,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01666670100688535",
          "emission(MCF)": 0.092,
          ConfidenceScore: 21.649,
          gpaqsHits: 1,
          coefficientOfVariation: 0.40720133967142347,
          totalScore: 53.69393301642883,
          Timestamp: "2024-10-04 14:10:00",
          UpperErrorMcfh: "0.02089581945465867",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.07888632178354944,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 59.279866032857655,
          ConfidencePercentage: 48.108,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01792919974989524",
          "emission(MCF)": 0.09,
          ConfidenceScore: 19.299,
          gpaqsHits: 1,
          coefficientOfVariation: 0.37753629213856393,
          totalScore: 52.566185393071805,
          Timestamp: "2024-10-04 14:20:00",
          UpperErrorMcfh: "0.022488213865619347",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.07703961762735191,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 62.246370786143615,
          ConfidencePercentage: 42.886,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.018048615046462865",
          "emission(MCF)": 0.084,
          ConfidenceScore: 19.312,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3812323466315661,
          totalScore: 52.396382668421694,
          Timestamp: "2024-10-04 14:20:00",
          UpperErrorMcfh: "0.02271937045185038",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.07198154493402432,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 61.876765336843384,
          ConfidencePercentage: 42.916,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.023692496067339693",
          "emission(MCF)": 0.11,
          ConfidenceScore: 18.868,
          gpaqsHits: 1,
          coefficientOfVariation: 0.33522287860204025,
          totalScore: 54.20385606989798,
          Timestamp: "2024-10-04 14:30:00",
          UpperErrorMcfh: "0.029712293992485855",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.09433262588663968,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 66.47771213979597,
          ConfidencePercentage: 41.93,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.019190958313574837",
          "emission(MCF)": 0.09,
          ConfidenceScore: 18.98,
          gpaqsHits: 1,
          coefficientOfVariation: 0.34309938009411256,
          totalScore: 53.93403099529437,
          Timestamp: "2024-10-04 14:30:00",
          UpperErrorMcfh: "0.023997407517432234",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.07745095757044486,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 65.69006199058875,
          ConfidencePercentage: 42.178,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.019710213153930085",
          "emission(MCF)": 0.087,
          ConfidenceScore: 18.126,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3653615534569161,
          totalScore: 51.87142232715419,
          Timestamp: "2024-10-04 14:40:00",
          UpperErrorMcfh: "0.024748022988794655",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.07441001130402933,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 63.46384465430839,
          ConfidencePercentage: 40.279,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.019743439928049198",
          "emission(MCF)": 0.088,
          ConfidenceScore: 18.115,
          gpaqsHits: 1,
          coefficientOfVariation: 0.371443767811565,
          totalScore: 51.55581160942175,
          Timestamp: "2024-10-04 14:40:00",
          UpperErrorMcfh: "0.02475080879521862",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.07534972423164135,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 62.855623218843505,
          ConfidencePercentage: 40.256,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.016501303373802834",
          "emission(MCF)": 0.074,
          ConfidenceScore: 18.63,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3627314668994583,
          totalScore: 52.56342665502709,
          Timestamp: "2024-10-04 14:50:00",
          UpperErrorMcfh: "0.020792663368488805",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07411695084416695,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 63.72685331005417,
          ConfidencePercentage: 41.4,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.019546617323284814",
          "emission(MCF)": 0.077,
          ConfidenceScore: 18.569,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3740142600064034,
          totalScore: 51.93078699967983,
          Timestamp: "2024-10-04 14:50:00",
          UpperErrorMcfh: "0.024440409169541797",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.0770183264550756,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 62.598573999359665,
          ConfidencePercentage: 41.263,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.017011449587005924",
          "emission(MCF)": 0.062,
          ConfidenceScore: 18.398,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3627314668994583,
          totalScore: 52.30592665502708,
          Timestamp: "2024-10-04 15:00:00",
          UpperErrorMcfh: "0.02140646562975041",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07411695084416695,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 63.72685331005417,
          ConfidencePercentage: 40.885,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02027036633976497",
          "emission(MCF)": 0.067,
          ConfidenceScore: 18.338,
          gpaqsHits: 1,
          coefficientOfVariation: 0.37799672911092314,
          totalScore: 51.47616354445384,
          Timestamp: "2024-10-04 15:00:00",
          UpperErrorMcfh: "0.025323208943685862",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.08076415235621259,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 62.200327088907684,
          ConfidencePercentage: 40.752,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.014485475730388817",
          "emission(MCF)": 0.041,
          ConfidenceScore: 18.257,
          gpaqsHits: 1,
          coefficientOfVariation: 0.09281278347720702,
          totalScore: 65.64536082613965,
          Timestamp: "2024-10-04 15:10:00",
          UpperErrorMcfh: "0.018129952388716564",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.060874045100119255,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 90.7187216522793,
          ConfidencePercentage: 40.572,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01718505895338436",
          "emission(MCF)": 0.045,
          ConfidenceScore: 18.187,
          gpaqsHits: 1,
          coefficientOfVariation: 0.16266055950763383,
          totalScore: 62.074972024618305,
          Timestamp: "2024-10-04 15:10:00",
          UpperErrorMcfh: "0.02160967584640155",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.06800300100508593,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 83.73394404923661,
          ConfidencePercentage: 40.416,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.014390102864048487",
          "emission(MCF)": 0.039,
          ConfidenceScore: 17.213,
          gpaqsHits: 1,
          coefficientOfVariation: 0.37091029383065266,
          totalScore: 50.579985308467364,
          Timestamp: "2024-10-04 15:20:00",
          UpperErrorMcfh: "0.01806612394091502",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07790086867018862,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 62.90897061693473,
          ConfidencePercentage: 38.251,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01751729032497697",
          "emission(MCF)": 0.041,
          ConfidenceScore: 17.152,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3957131342706968,
          totalScore: 49.27234328646516,
          Timestamp: "2024-10-04 15:20:00",
          UpperErrorMcfh: "0.021811373534530852",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.08268006104487605,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 60.428686572930324,
          ConfidencePercentage: 38.116,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.018940465461041556",
          "emission(MCF)": 0.027,
          ConfidenceScore: 17.207,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3159175891158783,
          totalScore: 53.32262054420609,
          Timestamp: "2024-10-04 15:30:00",
          UpperErrorMcfh: "0.02384063013043821",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.08108223977812698,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 68.40824108841217,
          ConfidencePercentage: 38.237,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.021932233712182614",
          "emission(MCF)": 0.028,
          ConfidenceScore: 17.145,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3848980938993712,
          totalScore: 49.80509530503144,
          Timestamp: "2024-10-04 15:30:00",
          UpperErrorMcfh: "0.027547847136024525",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.08384837465174796,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 61.510190610062885,
          ConfidencePercentage: 38.1,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.018878346075577507",
          "emission(MCF)": 0.013,
          ConfidenceScore: 16.69,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3775231853291412,
          totalScore: 49.667840733542945,
          Timestamp: "2024-10-04 15:40:00",
          UpperErrorMcfh: "0.02370606648907083",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07737521357222495,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 62.24768146708588,
          ConfidencePercentage: 37.088,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.021847883599432894",
          "emission(MCF)": 0.013,
          ConfidenceScore: 16.628,
          gpaqsHits: 1,
          coefficientOfVariation: 0.4175795453682644,
          totalScore: 47.597022731586776,
          Timestamp: "2024-10-04 15:40:00",
          UpperErrorMcfh: "0.02732388248756649",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.08061028795180473,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 58.242045463173554,
          ConfidencePercentage: 36.952,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.019749868496588444",
          "emission(MCF)": 0.039,
          ConfidenceScore: 17.103,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3775231853291412,
          totalScore: 50.12684073354294,
          Timestamp: "2024-10-04 16:20:00",
          UpperErrorMcfh: "0.02473645315022323",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07737521357222495,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 62.24768146708588,
          ConfidencePercentage: 38.006,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02275737998868564",
          "emission(MCF)": 0.045,
          ConfidenceScore: 17.046,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3775231853291411,
          totalScore: 50.063840733542946,
          Timestamp: "2024-10-04 16:20:00",
          UpperErrorMcfh: "0.028281989679684393",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.09011631284562116,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 62.24768146708589,
          ConfidencePercentage: 37.88,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.019808095456360003",
          "emission(MCF)": 0.039,
          ConfidenceScore: 16.068,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3775231853291412,
          totalScore: 48.97684073354294,
          Timestamp: "2024-10-04 16:20:00",
          UpperErrorMcfh: "0.02472593934794504",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07737521357222495,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 62.24768146708588,
          ConfidencePercentage: 35.706,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.022725052940441075",
          "emission(MCF)": 0.045,
          ConfidenceScore: 16.011,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3775231853291411,
          totalScore: 48.91384073354294,
          Timestamp: "2024-10-04 16:20:00",
          UpperErrorMcfh: "0.02848015969081911",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.09011631284562116,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 62.24768146708589,
          ConfidencePercentage: 35.58,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.019721459146391087",
          "emission(MCF)": 0.039,
          ConfidenceScore: 17.061,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3775231853291412,
          totalScore: 50.08034073354294,
          Timestamp: "2024-10-04 16:20:00",
          UpperErrorMcfh: "0.024765619919615928",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07737521357222495,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 62.24768146708588,
          ConfidencePercentage: 37.913,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02269894657470272",
          "emission(MCF)": 0.045,
          ConfidenceScore: 17.004,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3775231853291411,
          totalScore: 50.017340733542945,
          Timestamp: "2024-10-04 16:20:00",
          UpperErrorMcfh: "0.02848535052857633",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.09011631284562116,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 62.24768146708589,
          ConfidencePercentage: 37.787,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.019786414653231006",
          "emission(MCF)": 0.038,
          ConfidenceScore: 16.146,
          gpaqsHits: 1,
          coefficientOfVariation: 0.31176929938117964,
          totalScore: 52.35203503094102,
          Timestamp: "2024-10-04 16:20:00",
          UpperErrorMcfh: "0.024684819986873514",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07664440809190111,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 68.82307006188204,
          ConfidencePercentage: 35.881,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02277040974818265",
          "emission(MCF)": 0.045,
          ConfidenceScore: 16.09,
          gpaqsHits: 1,
          coefficientOfVariation: 0.31176929938117953,
          totalScore: 52.28903503094102,
          Timestamp: "2024-10-04 16:20:00",
          UpperErrorMcfh: "0.028311725371369615",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.08926516824447982,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 68.82307006188204,
          ConfidencePercentage: 35.755,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01687406414526833",
          "emission(MCF)": 0.022,
          ConfidenceScore: 16.149,
          gpaqsHits: 1,
          coefficientOfVariation: 0.14174057243534066,
          totalScore: 60.855971378232965,
          Timestamp: "2024-10-04 16:30:00",
          UpperErrorMcfh: "0.0211584994175678",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.06531017247181271,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 85.82594275646593,
          ConfidencePercentage: 35.886,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.019357488077057557",
          "emission(MCF)": 0.025,
          ConfidenceScore: 16.091,
          gpaqsHits: 1,
          coefficientOfVariation: 0.14174057243534044,
          totalScore: 60.79197137823299,
          Timestamp: "2024-10-04 16:30:00",
          UpperErrorMcfh: "0.0243004162189314",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.07606456464223638,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 85.82594275646596,
          ConfidencePercentage: 35.758,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.012972457676238783",
          "emission(MCF)": 0.011,
          ConfidenceScore: 15.152,
          gpaqsHits: 1,
          coefficientOfVariation: 0.20075444617225072,
          totalScore: 56.797277691387464,
          Timestamp: "2024-10-04 16:40:00",
          UpperErrorMcfh: "0.01642841504243594",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.06519696723483216,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 79.92455538277493,
          ConfidencePercentage: 33.67,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.015575326450386147",
          "emission(MCF)": 0.015,
          ConfidenceScore: 15.101,
          gpaqsHits: 1,
          coefficientOfVariation: 0.29476035404718953,
          totalScore: 52.040982297640525,
          Timestamp: "2024-10-04 16:40:00",
          UpperErrorMcfh: "0.019566032020980843",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.08991103286168013,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 70.52396459528106,
          ConfidencePercentage: 33.558,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01891558532041201",
          "emission(MCF)": 0.051,
          ConfidenceScore: 16.071,
          gpaqsHits: 1,
          coefficientOfVariation: 0.2153742558648484,
          totalScore: 57.08828720675758,
          Timestamp: "2024-10-04 17:20:00",
          UpperErrorMcfh: "0.023697803770603022",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.10228969820868293,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 78.46257441351516,
          ConfidencePercentage: 35.714,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.018770703408144497",
          "emission(MCF)": 0.052,
          ConfidenceScore: 16.061,
          gpaqsHits: 1,
          coefficientOfVariation: 0.21537425586484846,
          totalScore: 57.077287206757575,
          Timestamp: "2024-10-04 17:20:00",
          UpperErrorMcfh: "0.023631346364979426",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.10466398205729659,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 78.46257441351516,
          ConfidencePercentage: 35.692,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.018844886690614505",
          "emission(MCF)": 0.051,
          ConfidenceScore: 18.081,
          gpaqsHits: 1,
          coefficientOfVariation: 0.2153742558648484,
          totalScore: 59.321287206757574,
          Timestamp: "2024-10-04 17:20:00",
          UpperErrorMcfh: "0.023770117796001876",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.10228969820868293,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 78.46257441351516,
          ConfidencePercentage: 40.18,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01880931277376532",
          "emission(MCF)": 0.052,
          ConfidenceScore: 18.071,
          gpaqsHits: 1,
          coefficientOfVariation: 0.21537425586484846,
          totalScore: 59.31078720675758,
          Timestamp: "2024-10-04 17:20:00",
          UpperErrorMcfh: "0.023732163732753164",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.10466398205729659,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 78.46257441351516,
          ConfidencePercentage: 40.159,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.018818357606025026",
          "emission(MCF)": 0.051,
          ConfidenceScore: 18.102,
          gpaqsHits: 1,
          coefficientOfVariation: 0.2153742558648484,
          totalScore: 59.34478720675757,
          Timestamp: "2024-10-04 17:20:00",
          UpperErrorMcfh: "0.02369382048238083",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.10228969820868293,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 78.46257441351516,
          ConfidencePercentage: 40.227,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01872713515730517",
          "emission(MCF)": 0.052,
          ConfidenceScore: 18.092,
          gpaqsHits: 1,
          coefficientOfVariation: 0.21537425586484846,
          totalScore: 59.33378720675758,
          Timestamp: "2024-10-04 17:20:00",
          UpperErrorMcfh: "0.023647582151902723",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.10466398205729659,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 78.46257441351516,
          ConfidencePercentage: 40.205,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01888722663194723",
          "emission(MCF)": 0.051,
          ConfidenceScore: 18.244,
          gpaqsHits: 1,
          coefficientOfVariation: 0.2153742558648484,
          totalScore: 59.502787206757574,
          Timestamp: "2024-10-04 17:20:00",
          UpperErrorMcfh: "0.02364242390023605",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.10228969820868293,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 78.46257441351516,
          ConfidencePercentage: 40.543,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.018893828215441798",
          "emission(MCF)": 0.052,
          ConfidenceScore: 18.234,
          gpaqsHits: 1,
          coefficientOfVariation: 0.21537425586484846,
          totalScore: 59.49178720675758,
          Timestamp: "2024-10-04 17:20:00",
          UpperErrorMcfh: "0.02356988017812338",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.10466398205729659,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 78.46257441351516,
          ConfidencePercentage: 40.521,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.024822140035581856",
          "emission(MCF)": 0.039,
          ConfidenceScore: 17.09,
          gpaqsHits: 1,
          coefficientOfVariation: 0.7635691333177077,
          totalScore: 30.810043334114614,
          Timestamp: "2024-10-04 17:30:00",
          UpperErrorMcfh: "0.03128138188826638",
          likelyLeakLocation: "(4,2)",
          "avgRate(MCF/h)": 0.11556825119677577,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 23.64308666822923,
          ConfidencePercentage: 37.977,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.03897057555500318",
          "emission(MCF)": 0.062,
          ConfidenceScore: 17.958,
          gpaqsHits: 1,
          coefficientOfVariation: 0.8636901986232229,
          totalScore: 26.769490068838856,
          Timestamp: "2024-10-04 17:30:00",
          UpperErrorMcfh: "0.0491970757815585",
          likelyLeakLocation: "(1,4)",
          "avgRate(MCF/h)": 0.1869956755521428,
          "threshold(MCFH)": 0.050315247796911634,
          covPercentage: 13.630980137677707,
          ConfidencePercentage: 39.908,
          TotalChecks: 45,
        },
      },
    ],
    type: "gpaqsAlarm",
    key: 1,
  },
  {
    is_minimum_notification_threshold_met: true,
    alertRollingAverageConfig: 1,
    most_likely_event: {
      SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
      LowerErrorMcfh: "0.0076610467335943",
      "emission(MCF)": 0.025,
      ConfidenceScore: 19.465,
      gpaqsHits: 1,
      coefficientOfVariation: 0.3437217677662521,
      totalScore: 54.441911611687395,
      Timestamp: "2024-10-05 06:10:00",
      UpperErrorMcfh: "0.012601219012099301",
      likelyLeakLocation: "(6,1)",
      "avgRate(MCF/h)": 0.07412892805748666,
      "threshold(MCFH)": 0.052921596711135166,
      covPercentage: 65.62782322337479,
      ConfidencePercentage: 43.256,
      TotalChecks: 45,
    },
    SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
    mcfAlertHrThreshold: 0.052921596711135166,
    alarm_sent: true,
    alarmRollingAverageConfig: 1,
    mcfHrThreshold: 0.052921596711135166,
    recipients: ["fgomez@mangochango.com"],
    end_time: "2024-10-08 23:20:00",
    should_alarm: true,
    Timestamp: "2024-10-05 06:30:00",
    grid_timestamp: "2023-11-02 17:12:36",
    most_likely_event_II: {
      SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
      LowerErrorMcfh: "0.008176652482797989",
      "emission(MCF)": 0.026,
      ConfidenceScore: 19.431,
      gpaqsHits: 1,
      coefficientOfVariation: 0.3437217677662519,
      totalScore: 54.40441161168741,
      Timestamp: "2024-10-05 06:10:00",
      UpperErrorMcfh: "0.013317165930003412",
      likelyLeakLocation: "(5,1)",
      "avgRate(MCF/h)": 0.07896983115993764,
      "threshold(MCFH)": 0.052921596711135166,
      covPercentage: 65.62782322337482,
      ConfidencePercentage: 43.181,
      TotalChecks: 45,
    },
    follow_ups: [
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.008395822970720182",
          "emission(MCF)": 0.011,
          ConfidenceScore: 20.412,
          gpaqsHits: 1,
          coefficientOfVariation: 0.06160362322891,
          totalScore: 69.6003188385545,
          Timestamp: "2024-10-08 15:20:00",
          UpperErrorMcfh: "0.010196332792204818",
          likelyLeakLocation: "(5,2)",
          "avgRate(MCF/h)": 0.06480289640530719,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 93.839637677109,
          ConfidencePercentage: 45.361,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.009631343137934045",
          "emission(MCF)": 0.012,
          ConfidenceScore: 20.363,
          gpaqsHits: 1,
          coefficientOfVariation: 0.06160362322890983,
          totalScore: 69.54531883855451,
          Timestamp: "2024-10-08 15:20:00",
          UpperErrorMcfh: "0.011676384547268173",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.07394595169691418,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 93.83963767710901,
          ConfidencePercentage: 45.251,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.00823639189500686",
          "emission(MCF)": 0.013,
          ConfidenceScore: 19.415,
          gpaqsHits: 1,
          coefficientOfVariation: 0.26173564266628946,
          totalScore: 58.48571786668553,
          Timestamp: "2024-10-08 16:20:00",
          UpperErrorMcfh: "0.010008013406649547",
          likelyLeakLocation: "(5,2)",
          "avgRate(MCF/h)": 0.07605615714134352,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 73.82643573337106,
          ConfidencePercentage: 43.145,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.010249245842699126",
          "emission(MCF)": 0.016,
          ConfidenceScore: 19.334,
          gpaqsHits: 1,
          coefficientOfVariation: 0.2617356426662896,
          totalScore: 58.39571786668552,
          Timestamp: "2024-10-08 16:20:00",
          UpperErrorMcfh: "0.012509099176251535",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.09437371022323299,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 73.82643573337104,
          ConfidencePercentage: 42.965,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.012444465166662016",
          "emission(MCF)": 0.012,
          ConfidenceScore: 17.342,
          gpaqsHits: 1,
          coefficientOfVariation: 0.0397741319750982,
          totalScore: 67.28079340124509,
          Timestamp: "2024-10-08 16:20:00",
          UpperErrorMcfh: "0.015139149898294199",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.07277150743249494,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 96.02258680249018,
          ConfidencePercentage: 38.539,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.010946207141389502",
          "emission(MCF)": 0.012,
          ConfidenceScore: 17.396,
          gpaqsHits: 1,
          coefficientOfVariation: 0.211183837099951,
          totalScore: 58.76980814500245,
          Timestamp: "2024-10-08 16:20:00",
          UpperErrorMcfh: "0.013275377039835452",
          likelyLeakLocation: "(5,2)",
          "avgRate(MCF/h)": 0.07255986230712241,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 78.8816162900049,
          ConfidencePercentage: 38.658,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.014907669686383193",
          "emission(MCF)": 0.012,
          ConfidenceScore: 18.338,
          gpaqsHits: 1,
          coefficientOfVariation: 0.0397741319750982,
          totalScore: 68.38729340124509,
          Timestamp: "2024-10-08 16:20:00",
          UpperErrorMcfh: "0.018038275537294503",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.07277150743249494,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 96.02258680249018,
          ConfidencePercentage: 40.752,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.012970512923052558",
          "emission(MCF)": 0.012,
          ConfidenceScore: 18.392,
          gpaqsHits: 1,
          coefficientOfVariation: 0.211183837099951,
          totalScore: 59.87680814500245,
          Timestamp: "2024-10-08 16:20:00",
          UpperErrorMcfh: "0.015778083750436565",
          likelyLeakLocation: "(5,2)",
          "avgRate(MCF/h)": 0.07255986230712241,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 78.8816162900049,
          ConfidencePercentage: 40.872,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.01593838440970704",
          "emission(MCF)": 0.016,
          ConfidenceScore: 18.322,
          gpaqsHits: 1,
          coefficientOfVariation: 0.22670326885292333,
          totalScore: 59.02283655735383,
          Timestamp: "2024-10-08 16:20:00",
          UpperErrorMcfh: "0.01939782290616637",
          likelyLeakLocation: "(4,2)",
          "avgRate(MCF/h)": 0.09876781270551833,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 77.32967311470766,
          ConfidencePercentage: 40.716,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.012652288640049539",
          "emission(MCF)": 0.013,
          ConfidenceScore: 19.366,
          gpaqsHits: 1,
          coefficientOfVariation: 0.2516049554473601,
          totalScore: 58.937752227632,
          Timestamp: "2024-10-08 16:20:00",
          UpperErrorMcfh: "0.015447955543862316",
          likelyLeakLocation: "(5,2)",
          "avgRate(MCF/h)": 0.08052826089692254,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 74.83950445526399,
          ConfidencePercentage: 43.036,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.018975092769622504",
          "emission(MCF)": 0.016,
          ConfidenceScore: 18.325,
          gpaqsHits: 1,
          coefficientOfVariation: 0.22670326885292333,
          totalScore: 59.02633655735383,
          Timestamp: "2024-10-08 16:20:00",
          UpperErrorMcfh: "0.023170290292515924",
          likelyLeakLocation: "(4,2)",
          "avgRate(MCF/h)": 0.09876781270551833,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 77.32967311470766,
          ConfidencePercentage: 40.723,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.016270303320558024",
          "emission(MCF)": 0.013,
          ConfidenceScore: 19.369,
          gpaqsHits: 1,
          coefficientOfVariation: 0.2516049554473601,
          totalScore: 58.94125222763199,
          Timestamp: "2024-10-08 16:20:00",
          UpperErrorMcfh: "0.019819123073707697",
          likelyLeakLocation: "(5,2)",
          "avgRate(MCF/h)": 0.08052826089692254,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 74.83950445526399,
          ConfidencePercentage: 43.043,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.012313785901263013",
          "emission(MCF)": 0.027,
          ConfidenceScore: 19.528,
          gpaqsHits: 1,
          coefficientOfVariation: 0.0746702363955743,
          totalScore: 67.96498818022128,
          Timestamp: "2024-10-08 16:40:00",
          UpperErrorMcfh: "0.01601927384636888",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.08090247039121544,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 92.53297636044258,
          ConfidencePercentage: 43.397,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.021248421965828736",
          "emission(MCF)": 0.038,
          ConfidenceScore: 19.29,
          gpaqsHits: 1,
          coefficientOfVariation: 0.22274674056246363,
          totalScore: 60.295662971876816,
          Timestamp: "2024-10-08 16:40:00",
          UpperErrorMcfh: "0.025861399098765087",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.11359419771150528,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 77.72532594375363,
          ConfidencePercentage: 42.866,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.013914237058351665",
          "emission(MCF)": 0.027,
          ConfidenceScore: 19.518,
          gpaqsHits: 1,
          coefficientOfVariation: 0.0746702363955743,
          totalScore: 67.95348818022129,
          Timestamp: "2024-10-08 16:40:00",
          UpperErrorMcfh: "0.018067038958847047",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.08090247039121544,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 92.53297636044258,
          ConfidencePercentage: 43.374,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.023193726025229244",
          "emission(MCF)": 0.038,
          ConfidenceScore: 19.28,
          gpaqsHits: 1,
          coefficientOfVariation: 0.22274674056246363,
          totalScore: 60.28466297187681,
          Timestamp: "2024-10-08 16:40:00",
          UpperErrorMcfh: "0.028258404216308992",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.11359419771150528,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 77.72532594375363,
          ConfidencePercentage: 42.844,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.012844091748698163",
          "emission(MCF)": 0.013,
          ConfidenceScore: 20.5,
          gpaqsHits: 1,
          coefficientOfVariation: 0.12307543931517358,
          totalScore: 66.62422803424133,
          Timestamp: "2024-10-08 16:50:00",
          UpperErrorMcfh: "0.016458718665836133",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07609086038073293,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 87.69245606848264,
          ConfidencePercentage: 45.556,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.013627324906805073",
          "emission(MCF)": 0.013,
          ConfidenceScore: 20.405,
          gpaqsHits: 1,
          coefficientOfVariation: 0.20876370106800055,
          totalScore: 62.233314946599975,
          Timestamp: "2024-10-08 16:50:00",
          UpperErrorMcfh: "0.01669820779634189",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.07710980276652157,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 79.12362989319995,
          ConfidencePercentage: 45.343,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.014394116818585824",
          "emission(MCF)": 0.018,
          ConfidenceScore: 19.387,
          gpaqsHits: 1,
          coefficientOfVariation: 0.11294345838166847,
          totalScore: 65.89382708091658,
          Timestamp: "2024-10-08 17:10:00",
          UpperErrorMcfh: "0.017697798415751836",
          likelyLeakLocation: "(11,2)",
          "avgRate(MCF/h)": 0.10958512803874466,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 88.70565416183315,
          ConfidencePercentage: 43.082,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.013819891757718694",
          "emission(MCF)": 0.013,
          ConfidenceScore: 19.51,
          gpaqsHits: 1,
          coefficientOfVariation: 0.12307543931517358,
          totalScore: 65.52422803424132,
          Timestamp: "2024-10-08 17:10:00",
          UpperErrorMcfh: "0.017394207548643955",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07609086038073293,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 87.69245606848264,
          ConfidencePercentage: 43.356,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.014170183252276312",
          "emission(MCF)": 0.013,
          ConfidenceScore: 19.518,
          gpaqsHits: 1,
          coefficientOfVariation: 0.12307543931517358,
          totalScore: 65.53272803424132,
          Timestamp: "2024-10-08 17:10:00",
          UpperErrorMcfh: "0.01790792144729582",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.07609086038073293,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 87.69245606848264,
          ConfidencePercentage: 43.373,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.018103479372896074",
          "emission(MCF)": 0.016,
          ConfidenceScore: 19.405,
          gpaqsHits: 1,
          coefficientOfVariation: 0.14652492379708765,
          totalScore: 64.23475381014562,
          Timestamp: "2024-10-08 17:10:00",
          UpperErrorMcfh: "0.02213188828733543",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.09738493293300737,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 85.34750762029124,
          ConfidencePercentage: 43.122,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.012822529483851017",
          "emission(MCF)": 0.027,
          ConfidenceScore: 18.437,
          gpaqsHits: 1,
          coefficientOfVariation: 0.9266939682489664,
          totalScore: 24.15130158755168,
          Timestamp: "2024-10-08 17:30:00",
          UpperErrorMcfh: "0.01579827192503514",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.1628027547016309,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 7.330603175103356,
          ConfidencePercentage: 40.972,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.012467580752883137",
          "emission(MCF)": 0.026,
          ConfidenceScore: 18.529,
          gpaqsHits: 1,
          coefficientOfVariation: 0.9300552181225693,
          totalScore: 24.085239093871536,
          Timestamp: "2024-10-08 17:30:00",
          UpperErrorMcfh: "0.01578379955124624",
          likelyLeakLocation: "(7,3)",
          "avgRate(MCF/h)": 0.15441104955932494,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 6.99447818774307,
          ConfidencePercentage: 41.176,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.025460728141348855",
          "emission(MCF)": 0.027,
          ConfidenceScore: 18.44,
          gpaqsHits: 1,
          coefficientOfVariation: 0.9266939682489664,
          totalScore: 24.154801587551677,
          Timestamp: "2024-10-08 17:30:00",
          UpperErrorMcfh: "0.0312466469589338",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.1628027547016309,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 7.330603175103356,
          ConfidencePercentage: 40.979,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.03243389077719359",
          "emission(MCF)": 0.028,
          ConfidenceScore: 18.414,
          gpaqsHits: 1,
          coefficientOfVariation: 0.9367792290044017,
          totalScore: 23.621538549779913,
          Timestamp: "2024-10-08 17:30:00",
          UpperErrorMcfh: "0.03998688517369306",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.16693482796870507,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 6.322077099559831,
          ConfidencePercentage: 40.921,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02592312715254831",
          "emission(MCF)": 0.181,
          ConfidenceScore: 17.451,
          gpaqsHits: 1,
          coefficientOfVariation: 0.7678162615879816,
          totalScore: 30.999686920600922,
          Timestamp: "2024-10-08 17:50:00",
          UpperErrorMcfh: "0.031870921762626715",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.1809496809722316,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 23.218373841201846,
          ConfidencePercentage: 38.781,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.03339869040833428",
          "emission(MCF)": 0.17,
          ConfidenceScore: 17.918,
          gpaqsHits: 1,
          coefficientOfVariation: 0.9115907538644844,
          totalScore: 24.329462306775778,
          Timestamp: "2024-10-08 17:50:00",
          UpperErrorMcfh: "0.04071171149605215",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.16977702148573265,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 8.84092461355156,
          ConfidencePercentage: 39.818,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.029995279265848235",
          "emission(MCF)": 0.181,
          ConfidenceScore: 15.47,
          gpaqsHits: 1,
          coefficientOfVariation: 0.7678162615879816,
          totalScore: 28.79868692060092,
          Timestamp: "2024-10-08 17:50:00",
          UpperErrorMcfh: "0.036740285643732745",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.1809496809722316,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 23.218373841201846,
          ConfidencePercentage: 34.379,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.034456787198536815",
          "emission(MCF)": 0.17,
          ConfidenceScore: 15.437,
          gpaqsHits: 1,
          coefficientOfVariation: 0.9115907538644844,
          totalScore: 21.57246230677578,
          Timestamp: "2024-10-08 17:50:00",
          UpperErrorMcfh: "0.042368071693287275",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.16977702148573265,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 8.84092461355156,
          ConfidencePercentage: 34.304,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.03307508007512998",
          "emission(MCF)": 0.167,
          ConfidenceScore: 15.44,
          gpaqsHits: 1,
          coefficientOfVariation: 0.5871929673159952,
          totalScore: 37.796351634200235,
          Timestamp: "2024-10-08 18:00:00",
          UpperErrorMcfh: "0.04057097294720767",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.2007941320167317,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 41.28070326840047,
          ConfidencePercentage: 34.312,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.03344814595602803",
          "emission(MCF)": 0.242,
          ConfidenceScore: 15.46,
          gpaqsHits: 1,
          coefficientOfVariation: 0.7685489495521397,
          totalScore: 28.750552522393015,
          Timestamp: "2024-10-08 18:00:00",
          UpperErrorMcfh: "0.0409039149950199",
          likelyLeakLocation: "(10,2)",
          "avgRate(MCF/h)": 0.2902178135656129,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 23.14510504478603,
          ConfidencePercentage: 34.356,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.019602532926201097",
          "emission(MCF)": 0.084,
          ConfidenceScore: 15.213,
          gpaqsHits: 1,
          coefficientOfVariation: 0.5062762116505233,
          totalScore: 41.589689417473835,
          Timestamp: "2024-10-08 18:10:00",
          UpperErrorMcfh: "0.023873749810380766",
          likelyLeakLocation: "(1,4)",
          "avgRate(MCF/h)": 0.12654599067513583,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 49.372378834947675,
          ConfidencePercentage: 33.807,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.038125087708891094",
          "emission(MCF)": 0.121,
          ConfidenceScore: 15.462,
          gpaqsHits: 1,
          coefficientOfVariation: 0.5682419527885306,
          totalScore: 38.76790236057347,
          Timestamp: "2024-10-08 18:10:00",
          UpperErrorMcfh: "0.04653106630921601",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.18194361902950393,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 43.17580472114694,
          ConfidencePercentage: 34.36,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.044407238870856174",
          "emission(MCF)": 0.093,
          ConfidenceScore: 18.956,
          gpaqsHits: 1,
          coefficientOfVariation: 0.48309818884032385,
          totalScore: 46.90759055798381,
          Timestamp: "2024-10-08 18:20:00",
          UpperErrorMcfh: "0.053938490872482474",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.18657281669279846,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 51.69018111596762,
          ConfidencePercentage: 42.125,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.03445708348784522",
          "emission(MCF)": 0.063,
          ConfidenceScore: 18.205,
          gpaqsHits: 1,
          coefficientOfVariation: 0.5062762116505233,
          totalScore: 44.914189417473835,
          Timestamp: "2024-10-08 18:20:00",
          UpperErrorMcfh: "0.04229586493757366",
          likelyLeakLocation: "(1,4)",
          "avgRate(MCF/h)": 0.12654599067513583,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 49.372378834947675,
          ConfidencePercentage: 40.456,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.035230871580015505",
          "emission(MCF)": 0.052,
          ConfidenceScore: 19.05,
          gpaqsHits: 1,
          coefficientOfVariation: 0.22826704333075573,
          totalScore: 59.752647833462206,
          Timestamp: "2024-10-08 18:30:00",
          UpperErrorMcfh: "0.043358961673144895",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.1552062431429913,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 77.17329566692442,
          ConfidencePercentage: 42.332,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.04233556400795434",
          "emission(MCF)": 0.065,
          ConfidenceScore: 18.946,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3391031977753255,
          totalScore: 54.09584011123373,
          Timestamp: "2024-10-08 18:30:00",
          UpperErrorMcfh: "0.052181955405943614",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.19397232634501743,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 66.08968022246745,
          ConfidencePercentage: 42.102,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.03939587656777735",
          "emission(MCF)": 0.026,
          ConfidenceScore: 18.447,
          gpaqsHits: 1,
          coefficientOfVariation: 0.22826704333075573,
          totalScore: 59.082647833462204,
          Timestamp: "2024-10-08 18:40:00",
          UpperErrorMcfh: "0.04876987364622073",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.1552062431429913,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 77.17329566692442,
          ConfidencePercentage: 40.992,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.05115482067635074",
          "emission(MCF)": 0.035,
          ConfidenceScore: 18.314,
          gpaqsHits: 1,
          coefficientOfVariation: 0.33803575994920804,
          totalScore: 53.4472120025396,
          Timestamp: "2024-10-08 18:40:00",
          UpperErrorMcfh: "0.06304762271095286",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.2082942525570184,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 66.1964240050792,
          ConfidencePercentage: 40.698,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.037708005205247856",
          "emission(MCF)": 0.133,
          ConfidenceScore: 16.396,
          gpaqsHits: 1,
          coefficientOfVariation: 0.24293906127994605,
          totalScore: 56.0710469360027,
          Timestamp: "2024-10-08 19:00:00",
          UpperErrorMcfh: "0.04649624300209231",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.16011334434212987,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 75.7060938720054,
          ConfidencePercentage: 36.436,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.052774404980520016",
          "emission(MCF)": 0.189,
          ConfidenceScore: 16.27,
          gpaqsHits: 1,
          coefficientOfVariation: 0.28895473882613343,
          totalScore: 53.630763058693326,
          Timestamp: "2024-10-08 19:00:00",
          UpperErrorMcfh: "0.06524213227715867",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.22691335218287692,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 71.10452611738665,
          ConfidencePercentage: 36.157,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.034049993500649516",
          "emission(MCF)": 0.133,
          ConfidenceScore: 16.379,
          gpaqsHits: 1,
          coefficientOfVariation: 0.24293906127994605,
          totalScore: 56.0520469360027,
          Timestamp: "2024-10-08 19:00:00",
          UpperErrorMcfh: "0.041935809903168766",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.16011334434212987,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 75.7060938720054,
          ConfidencePercentage: 36.398,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.04820604688958787",
          "emission(MCF)": 0.189,
          ConfidenceScore: 16.253,
          gpaqsHits: 1,
          coefficientOfVariation: 0.28895473882613343,
          totalScore: 53.61176305869333,
          Timestamp: "2024-10-08 19:00:00",
          UpperErrorMcfh: "0.059428737162853185",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.22691335218287692,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 71.10452611738665,
          ConfidencePercentage: 36.119,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.03445259511961549",
          "emission(MCF)": 0.113,
          ConfidenceScore: 15.379,
          gpaqsHits: 1,
          coefficientOfVariation: 0.23949384237617155,
          totalScore: 55.113307881191425,
          Timestamp: "2024-10-08 19:10:00",
          UpperErrorMcfh: "0.04254703113624065",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.1701828013334378,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 76.05061576238285,
          ConfidencePercentage: 34.176,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.04855257635476344",
          "emission(MCF)": 0.16,
          ConfidenceScore: 15.255,
          gpaqsHits: 1,
          coefficientOfVariation: 0.3103976018706398,
          totalScore: 51.42961990646801,
          Timestamp: "2024-10-08 19:10:00",
          UpperErrorMcfh: "0.05964384123004785",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.23935569400595877,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 68.96023981293602,
          ConfidencePercentage: 33.899,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.03502679829858957",
          "emission(MCF)": 0.096,
          ConfidenceScore: 15.4,
          gpaqsHits: 1,
          coefficientOfVariation: 0.06503466500780972,
          totalScore: 63.85976674960951,
          Timestamp: "2024-10-08 19:20:00",
          UpperErrorMcfh: "0.04325051108772147",
          likelyLeakLocation: "(9,2)",
          "avgRate(MCF/h)": 0.19242462773627303,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 93.49653349921903,
          ConfidencePercentage: 34.223,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.048014499578297676",
          "emission(MCF)": 0.131,
          ConfidenceScore: 15.312,
          gpaqsHits: 1,
          coefficientOfVariation: 0.06471897488580415,
          totalScore: 63.77705125570979,
          Timestamp: "2024-10-08 19:20:00",
          UpperErrorMcfh: "0.059432543585358086",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.2616362277167266,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 93.52810251141959,
          ConfidencePercentage: 34.026,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.04147496691626483",
          "emission(MCF)": 0.064,
          ConfidenceScore: 15.365,
          gpaqsHits: 1,
          coefficientOfVariation: 0.29994085699561385,
          totalScore: 52.07545715021931,
          Timestamp: "2024-10-08 19:30:00",
          UpperErrorMcfh: "0.0508636490569939",
          likelyLeakLocation: "(10,2)",
          "avgRate(MCF/h)": 0.19268944444312952,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 70.0059143004386,
          ConfidencePercentage: 34.145,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.059775669015743584",
          "emission(MCF)": 0.071,
          ConfidenceScore: 15.357,
          gpaqsHits: 1,
          coefficientOfVariation: 0.33294678463049443,
          totalScore: 50.41566076847528,
          Timestamp: "2024-10-08 19:30:00",
          UpperErrorMcfh: "0.07404002058431293",
          likelyLeakLocation: "(11,2)",
          "avgRate(MCF/h)": 0.2133837355304915,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 66.70532153695056,
          ConfidencePercentage: 34.126,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.023963440657012818",
          "emission(MCF)": 0.016,
          ConfidenceScore: 16.761,
          gpaqsHits: 1,
          coefficientOfVariation: 0.050643863764966005,
          totalScore: 66.0918068117517,
          Timestamp: "2024-10-08 19:40:00",
          UpperErrorMcfh: "0.029729174966874918",
          likelyLeakLocation: "(5,2)",
          "avgRate(MCF/h)": 0.09487486639901099,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 94.9356136235034,
          ConfidencePercentage: 37.248,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.0281382593298012",
          "emission(MCF)": 0.019,
          ConfidenceScore: 16.681,
          gpaqsHits: 1,
          coefficientOfVariation: 0.0623719171892823,
          totalScore: 65.41640414053589,
          Timestamp: "2024-10-08 19:40:00",
          UpperErrorMcfh: "0.03501849850053737",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.11468953563957347,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 93.76280828107177,
          ConfidencePercentage: 37.07,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.019187235499105205",
          "emission(MCF)": 0.063,
          ConfidenceScore: 14.216,
          gpaqsHits: 1,
          coefficientOfVariation: 0.050643863764966005,
          totalScore: 63.2638068117517,
          Timestamp: "2024-10-08 20:20:00",
          UpperErrorMcfh: "0.023768288604421466",
          likelyLeakLocation: "(5,2)",
          "avgRate(MCF/h)": 0.09487486639901099,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 94.9356136235034,
          ConfidencePercentage: 31.592,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02143018851255717",
          "emission(MCF)": 0.071,
          ConfidenceScore: 13.169,
          gpaqsHits: 1,
          coefficientOfVariation: 0.050265597247229545,
          totalScore: 62.11922013763852,
          Timestamp: "2024-10-08 20:20:00",
          UpperErrorMcfh: "0.02664418513953253",
          likelyLeakLocation: "(4,2)",
          "avgRate(MCF/h)": 0.10678660550969492,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 94.97344027527704,
          ConfidencePercentage: 29.265,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02376680159498148",
          "emission(MCF)": 0.079,
          ConfidenceScore: 14.185,
          gpaqsHits: 1,
          coefficientOfVariation: 0.050643863764966005,
          totalScore: 63.2293068117517,
          Timestamp: "2024-10-08 20:20:00",
          UpperErrorMcfh: "0.02966215780494922",
          likelyLeakLocation: "(5,2)",
          "avgRate(MCF/h)": 0.09487486639901099,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 94.9356136235034,
          ConfidencePercentage: 31.523,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02660512531238806",
          "emission(MCF)": 0.089,
          ConfidenceScore: 13.138,
          gpaqsHits: 1,
          coefficientOfVariation: 0.050265597247229545,
          totalScore: 62.08472013763852,
          Timestamp: "2024-10-08 20:20:00",
          UpperErrorMcfh: "0.03306944005535248",
          likelyLeakLocation: "(4,2)",
          "avgRate(MCF/h)": 0.10678660550969492,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 94.97344027527704,
          ConfidencePercentage: 29.196,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02380769742312735",
          "emission(MCF)": 0.089,
          ConfidenceScore: 14.16,
          gpaqsHits: 1,
          coefficientOfVariation: 0.13841201561740502,
          totalScore: 58.812399219129745,
          Timestamp: "2024-10-08 20:20:00",
          UpperErrorMcfh: "0.02936498913777507",
          likelyLeakLocation: "(5,2)",
          "avgRate(MCF/h)": 0.08903376517966248,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 86.1587984382595,
          ConfidencePercentage: 31.466,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.026598094094043255",
          "emission(MCF)": 0.101,
          ConfidenceScore: 13.112,
          gpaqsHits: 1,
          coefficientOfVariation: 0.13096504686103472,
          totalScore: 58.02074765694827,
          Timestamp: "2024-10-08 20:20:00",
          UpperErrorMcfh: "0.03299686485280672",
          likelyLeakLocation: "(4,2)",
          "avgRate(MCF/h)": 0.10057589737782892,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 86.90349531389653,
          ConfidencePercentage: 29.138,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.025298118650462807",
          "emission(MCF)": 0.117,
          ConfidenceScore: 13.088,
          gpaqsHits: 1,
          coefficientOfVariation: 0.13096504686103472,
          totalScore: 57.99424765694826,
          Timestamp: "2024-10-08 20:20:00",
          UpperErrorMcfh: "0.031516073019322875",
          likelyLeakLocation: "(4,2)",
          "avgRate(MCF/h)": 0.10057589737782892,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 86.90349531389653,
          ConfidencePercentage: 29.085,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02252585629250637",
          "emission(MCF)": 0.111,
          ConfidenceScore: 14.139,
          gpaqsHits: 1,
          coefficientOfVariation: 0.18828551654255768,
          totalScore: 56.29522417287212,
          Timestamp: "2024-10-08 20:20:00",
          UpperErrorMcfh: "0.02789522392920754",
          likelyLeakLocation: "(5,2)",
          "avgRate(MCF/h)": 0.09550632582769625,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 81.17144834574424,
          ConfidencePercentage: 31.419,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.026562854803227523",
          "emission(MCF)": 0.144,
          ConfidenceScore: 17.039,
          gpaqsHits: 1,
          coefficientOfVariation: 0.42538587082283047,
          totalScore: 47.66270645885848,
          Timestamp: "2024-10-08 20:30:00",
          UpperErrorMcfh: "0.032966776538767284",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.12358611089811301,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 57.461412917716956,
          ConfidencePercentage: 37.864,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02421322612064051",
          "emission(MCF)": 0.137,
          ConfidenceScore: 16.626,
          gpaqsHits: 1,
          coefficientOfVariation: 0.42026573099548825,
          totalScore: 47.459713450225586,
          Timestamp: "2024-10-08 20:30:00",
          UpperErrorMcfh: "0.029969105776392325",
          likelyLeakLocation: "(5,2)",
          "avgRate(MCF/h)": 0.1177110405504882,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 57.97342690045117,
          ConfidencePercentage: 36.946,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.033719279470883004",
          "emission(MCF)": 0.143,
          ConfidenceScore: 16.06,
          gpaqsHits: 1,
          coefficientOfVariation: 0.4277978221991074,
          totalScore: 46.45510889004463,
          Timestamp: "2024-10-08 20:40:00",
          UpperErrorMcfh: "0.041653593540143176",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.12296916112606074,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 57.22021778008926,
          ConfidencePercentage: 35.69,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.032616922416418455",
          "emission(MCF)": 0.137,
          ConfidenceScore: 15.748,
          gpaqsHits: 1,
          coefficientOfVariation: 0.42584970052443927,
          totalScore: 46.20451497377803,
          Timestamp: "2024-10-08 20:40:00",
          UpperErrorMcfh: "0.04040516713489734",
          likelyLeakLocation: "(5,2)",
          "avgRate(MCF/h)": 0.11702157306834453,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 57.41502994755607,
          ConfidencePercentage: 34.994,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.03454106157513511",
          "emission(MCF)": 0.141,
          ConfidenceScore: 16.083,
          gpaqsHits: 1,
          coefficientOfVariation: 0.4417704665306425,
          totalScore: 45.781976673467874,
          Timestamp: "2024-10-08 20:50:00",
          UpperErrorMcfh: "0.04287089251524552",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.12095819669559241,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 55.82295334693575,
          ConfidencePercentage: 35.741,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.031943920677288004",
          "emission(MCF)": 0.13,
          ConfidenceScore: 16.115,
          gpaqsHits: 1,
          coefficientOfVariation: 0.4490307213134716,
          totalScore: 45.45396393432642,
          Timestamp: "2024-10-08 20:50:00",
          UpperErrorMcfh: "0.039475169221339604",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.11146650478500526,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 55.096927868652834,
          ConfidencePercentage: 35.811,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.0349959351464673",
          "emission(MCF)": 0.139,
          ConfidenceScore: 17.102,
          gpaqsHits: 1,
          coefficientOfVariation: 0.4654226445773612,
          totalScore: 45.73136777113194,
          Timestamp: "2024-10-08 21:00:00",
          UpperErrorMcfh: "0.04320430600642801",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.11887867486166555,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 53.457735542263876,
          ConfidencePercentage: 38.005,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.03236590707633234",
          "emission(MCF)": 0.128,
          ConfidenceScore: 17.134,
          gpaqsHits: 1,
          coefficientOfVariation: 0.47151941098305866,
          totalScore: 45.46152945084707,
          Timestamp: "2024-10-08 21:00:00",
          UpperErrorMcfh: "0.03998385200822984",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.10963195158722318,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 52.84805890169413,
          ConfidencePercentage: 38.075,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.032480881781298244",
          "emission(MCF)": 0.126,
          ConfidenceScore: 17.151,
          gpaqsHits: 1,
          coefficientOfVariation: 0.4555383127022302,
          totalScore: 46.27958436488849,
          Timestamp: "2024-10-08 21:10:00",
          UpperErrorMcfh: "0.040363945270391915",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.12572298573796029,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 54.44616872977698,
          ConfidencePercentage: 38.113,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.030090823927643954",
          "emission(MCF)": 0.116,
          ConfidenceScore: 17.182,
          gpaqsHits: 1,
          coefficientOfVariation: 0.4617921335741829,
          totalScore: 46.001393321290855,
          Timestamp: "2024-10-08 21:10:00",
          UpperErrorMcfh: "0.03734353577023072",
          likelyLeakLocation: "(6,1)",
          "avgRate(MCF/h)": 0.11597771351518281,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 53.82078664258171,
          ConfidencePercentage: 38.182,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02384102405187865",
          "emission(MCF)": 0.093,
          ConfidenceScore: 16.222,
          gpaqsHits: 1,
          coefficientOfVariation: 0.45651021682256576,
          totalScore: 45.19898915887171,
          Timestamp: "2024-10-08 21:20:00",
          UpperErrorMcfh: "0.02943540559561156",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.11199321424641175,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 54.34897831774342,
          ConfidencePercentage: 36.049,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.030997036955644788",
          "emission(MCF)": 0.111,
          ConfidenceScore: 16.101,
          gpaqsHits: 1,
          coefficientOfVariation: 0.4580305372317703,
          totalScore: 44.98897313841148,
          Timestamp: "2024-10-08 21:20:00",
          UpperErrorMcfh: "0.038458837827350434",
          likelyLeakLocation: "(5,1)",
          "avgRate(MCF/h)": 0.13295065142502238,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 54.19694627682296,
          ConfidencePercentage: 35.781,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.0249479369987145",
          "emission(MCF)": 0.107,
          ConfidenceScore: 15.323,
          gpaqsHits: 1,
          coefficientOfVariation: 0.384285236045813,
          totalScore: 47.81123819770935,
          Timestamp: "2024-10-08 21:30:00",
          UpperErrorMcfh: "0.030865950035444567",
          likelyLeakLocation: "(11,2)",
          "avgRate(MCF/h)": 0.15981667511611028,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 61.5714763954187,
          ConfidencePercentage: 34.051,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02475683285309401",
          "emission(MCF)": 0.108,
          ConfidenceScore: 15.343,
          gpaqsHits: 1,
          coefficientOfVariation: 0.4130979498312755,
          totalScore: 46.39310250843623,
          Timestamp: "2024-10-08 21:30:00",
          UpperErrorMcfh: "0.030632716992639367",
          likelyLeakLocation: "(10,2)",
          "avgRate(MCF/h)": 0.16216503221129308,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 58.690205016872454,
          ConfidencePercentage: 34.096,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02310486348868025",
          "emission(MCF)": 0.063,
          ConfidenceScore: 16.279,
          gpaqsHits: 1,
          coefficientOfVariation: 0.16062547305802438,
          totalScore: 60.05622634709878,
          Timestamp: "2024-10-08 21:40:00",
          UpperErrorMcfh: "0.028653851945477336",
          likelyLeakLocation: "(11,2)",
          "avgRate(MCF/h)": 0.125324105631892,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 83.93745269419756,
          ConfidencePercentage: 36.175,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.02287645685764318",
          "emission(MCF)": 0.062,
          ConfidenceScore: 16.297,
          gpaqsHits: 1,
          coefficientOfVariation: 0.1800338967094975,
          totalScore: 59.106305164525125,
          Timestamp: "2024-10-08 21:40:00",
          UpperErrorMcfh: "0.028017918894531875",
          likelyLeakLocation: "(10,2)",
          "avgRate(MCF/h)": 0.12458794644875248,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 81.99661032905026,
          ConfidencePercentage: 36.216,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.015095433674944531",
          "emission(MCF)": 0.023,
          ConfidenceScore: 16.135,
          gpaqsHits: 1,
          coefficientOfVariation: 0.0764246103482554,
          totalScore: 64.10676948258723,
          Timestamp: "2024-10-08 21:50:00",
          UpperErrorMcfh: "0.018597587926849547",
          likelyLeakLocation: "(4,2)",
          "avgRate(MCF/h)": 0.07018495688344721,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 92.35753896517446,
          ConfidencePercentage: 35.856,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.013170809894414909",
          "emission(MCF)": 0.021,
          ConfidenceScore: 16.226,
          gpaqsHits: 1,
          coefficientOfVariation: 0.12467317274361442,
          totalScore: 61.79534136281927,
          Timestamp: "2024-10-08 21:50:00",
          UpperErrorMcfh: "0.016280435910904372",
          likelyLeakLocation: "(6,2)",
          "avgRate(MCF/h)": 0.06409864057941944,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 87.53268272563855,
          ConfidencePercentage: 36.058,
          TotalChecks: 45,
        },
      },
      {
        most_likely_event: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.013341786665912616",
          "emission(MCF)": 0.01,
          ConfidenceScore: 15.205,
          gpaqsHits: 1,
          coefficientOfVariation: 0.05721820266595605,
          totalScore: 64.0335898667022,
          Timestamp: "2024-10-08 22:00:00",
          UpperErrorMcfh: "0.01657674731667523",
          likelyLeakLocation: "(5,2)",
          "avgRate(MCF/h)": 0.06242159760125513,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 94.2781797334044,
          ConfidencePercentage: 33.789,
          TotalChecks: 45,
        },
        most_likely_event_II: {
          SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
          LowerErrorMcfh: "0.015086843345422164",
          "emission(MCF)": 0.012,
          ConfidenceScore: 15.156,
          gpaqsHits: 1,
          coefficientOfVariation: 0.0764246103482554,
          totalScore: 63.01876948258723,
          Timestamp: "2024-10-08 22:00:00",
          UpperErrorMcfh: "0.01862005944954968",
          likelyLeakLocation: "(4,2)",
          "avgRate(MCF/h)": 0.07018495688344721,
          "threshold(MCFH)": 0.05614620445291698,
          covPercentage: 92.35753896517446,
          ConfidencePercentage: 33.68,
          TotalChecks: 45,
        },
      },
    ],
    type: "gpaqsAlarm",
    key: 2,
  },
];
