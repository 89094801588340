import { Table } from "antd";
import { FC, useState, useEffect, useRef, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import {
  setStartTimestamp,
  setEndTimestamp,
  setCurrentTab,
} from "../../store/selections/slice";
import {
  DailyEmission,
  DailyEmissionsMetaData,
} from "../../store/dailyEmissions/types";
import { useGetGlobalConfigQuery } from "../../store/globalConfig/api";
import {
  mcfHrToSiteUnitsStr,
  mcfToSiteUnits,
  mcfToSiteUnitsStr,
  QuantityUnit,
} from "../../helpers/unitsHelpers";
import { formatDate, getNewStartTimestamp } from "../TimeRangePicker/helpers";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  BarController,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartEvent,
} from "chart.js";
import { Chart, getElementAtEvent } from "react-chartjs-2";
import DailyEmissionSelection from "./SelectionComponent/DailyEmissionSelection";
import { DailyEmissionsProps } from "./types";
import DisplayBox from "./CurrentDisplayBox/CurrentDisplayBox";
import styles from "./styles.module.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  BarController,
  Title,
  Tooltip,
  Legend
);

const DailyEmissions: FC<DailyEmissionsProps> = ({
  siteId,
  units,
  onChangeUnits,
  form,
  dailyEmissions,
  dailyEmissionsMetaData,
  minDisplayDays,
  isPinewave,
  pinewaveOilProduction,
}) => {
  const chartRef = useRef();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const globalConfig = useGetGlobalConfigQuery(null).data;
  const initialChartData = { labels: [], datasets: [] };
  const [chartData, setChartData] =
    useState<ChartData<"bar" | "line">>(initialChartData);

  const onClickChart = (event: MouseEvent<HTMLCanvasElement>): void => {
    if (chartData?.labels == null) return;
    // will fire when clicking on empty region of chart with no data,
    // so try/catch is needed
    try {
      if (chartRef.current !== undefined) {
        const [element] = getElementAtEvent(chartRef.current, event);
        const { index } = element;
        const newDateString = chartData.labels[index] as string;
        const newEndDate = new Date(newDateString + " 00:00:00");
        newEndDate.setDate(newEndDate.getDate() + 1);
        const newEndTimestamp = formatDate(newEndDate);
        const newStartTimestamp = getNewStartTimestamp(newEndTimestamp, 1, 0);
        dispatch(setStartTimestamp(newStartTimestamp));
        dispatch(setEndTimestamp(newEndTimestamp));

        navigate(`/sites/${siteId}`, { replace: true });
        dispatch(setCurrentTab("Site Data"));
      }
    } catch {}
  };

  const metaData: DailyEmissionsMetaData = {
    current7DayAvgMcf: Number(
      mcfHrToSiteUnitsStr(
        dailyEmissionsMetaData.current7DayAvgMcf,
        units,
        globalConfig,
        false
      )
    ),
    current90DayAvgMcf: Number(
      mcfHrToSiteUnitsStr(
        dailyEmissionsMetaData.current90DayAvgMcf,
        units,
        globalConfig,
        false
      )
    ),
  };

  const data: DailyEmission[] = dailyEmissions.map((ele, idx) => {
    const avg7Day = mcfToSiteUnitsStr(
      ele["7DayAvg(MCF)"],
      units,
      globalConfig,
      false
    );
    const avg90Day = mcfToSiteUnitsStr(
      ele["90DayAvg(MCF)"],
      units,
      globalConfig,
      false
    );
    const totalEmission = mcfToSiteUnitsStr(
      ele["totalEmission(MCF)"],
      units,
      globalConfig,
      false
    );

    return {
      ...ele,
      "7DayAvg": avg7Day,
      "90DayAvg": avg90Day,
      totalEmission,
      key: idx,
    };
  });
  const reversedData = [...data].reverse();

  const yDataMax = Math.max(...data.map((el) => el["totalEmission(MCF)"]));
  const yChartMax = yDataMax * 1.1;

  const onHoverBar = (event: ChartEvent, chartElement: object[]): void => {
    if (event.native === null) return;

    const canvas = event.native.target as HTMLCanvasElement;

    if (chartElement.length === 1) {
      canvas.style.cursor = "pointer";
    }
    if (chartElement.length === 0) {
      canvas.style.cursor = "default";
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: `Daily Emissions (${units})`,
        font: {
          size: 24,
          weight: "bold" as const,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
          font: {
            size: 20,
            weight: "bold" as const,
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        type: "linear" as const,
        display: true,
        title: {
          display: true,
          text: `Emissions (${units})`,
          font: {
            size: 20,
            weight: "bold" as const,
          },
        },
        suggestedMax: yChartMax,
      },
      y1: {
        display: isPinewave,
        type: "linear" as const,
        position: "right" as const,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Oil Production (Barrels)",
          font: {
            size: 20,
            weight: "bold" as const,
          },
        },
      },
    },
    onHover: onHoverBar,
  };

  const labels = data.map((element) => element.Timestamp);
  const colors = [
    ["#CD5C5C", "#8B0000"], // indian red
    ["#D4F1F4", "#75E6DA"], // baby blue
    ["#C71585", "#8B2252"], // medium violet red
    ["#77DD77", "#52D452"], // lime green
    ["#EEB5EB", "#C26DBC"], // lavender
  ];

  const getChartData = (): ChartData<"bar" | "line"> => {
    const result = {
      labels,
      datasets: [
        {
          label: "7 Day Average",
          type: "line" as const,
          backgroundColor: colors[0][0],
          borderColor: colors[0][0],
          data: dailyEmissions.map((el) => {
            return mcfToSiteUnits(el["7DayAvg(MCF)"], units, globalConfig);
          }),
          spanGaps: true,
          pointRadius: 0,
        },
        {
          label: "90 Day Average",
          type: "line" as const,
          backgroundColor: colors[2][0],
          borderColor: colors[2][0],
          data: dailyEmissions.map((el) => {
            return mcfToSiteUnits(el["90DayAvg(MCF)"], units, globalConfig);
          }),
          pointRadius: 0,
          borderDash: [5, 5],
        },
        {
          label: "Daily Emissions",
          type: "bar" as const,
          yAxisID: "y",
          backgroundColor: colors[1][0],
          data: dailyEmissions.map((el) => {
            return mcfToSiteUnits(
              el["totalEmission(MCF)"],
              units,
              globalConfig
            );
          }),
        },
      ],
    };

    if (isPinewave && pinewaveOilProduction.length > 1) {
      result.datasets.push({
        label: "Oil Production",
        type: "bar" as const,
        yAxisID: "y1",
        backgroundColor: colors[3][0],
        data: pinewaveOilProduction.map((el) => {
          return Number(el.oilProd);
        }),
      });
    }

    return result;
  };

  const columns = [
    {
      title: "Day",
      dataIndex: "Timestamp",
      key: "Timestamp",
    },
    {
      title: `Total Emission (${units})`,
      dataIndex: "totalEmission",
      key: "totalEmission",
    },
    {
      title: `7 Day Average (${units})`,
      dataIndex: "7DayAvg",
      key: "7DayAvg",
    },
    {
      title: `90 Day Average (${units})`,
      dataIndex: "90DayAvg",
      key: "90DayAvg",
    },
  ];

  useEffect(() => {
    setChartData(getChartData());
  }, [dailyEmissions, pinewaveOilProduction, units]);

  return (
    <div style={{ width: "99%" }}>
      <DailyEmissionSelection
        form={form}
        onChangeUnits={(units: QuantityUnit) => {
          onChangeUnits(units);
        }}
        units={units}
        minDisplayDays={minDisplayDays}
      />
      <div className={styles.Container}>
        <Chart
          ref={chartRef}
          type="bar"
          options={options}
          data={chartData}
          onClick={onClickChart}
        />
        <div className={styles.DisplayBoxContainer}>
          <DisplayBox dailyEmissionsMetaData={metaData} units={units} />
        </div>
      </div>
      <Table dataSource={reversedData} columns={columns} />
    </div>
  );
};

export default DailyEmissions;
