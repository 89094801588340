export const mockGpaqsRollingAverages = {
  "7_days_average_emission_rates": "0.019901865671641792",
  Timestamp: "2024-10-29 00:00:00",
  SiteId: "9b4f45c4-aabb-4a17-be1a-5cfd1c96a5f6",
  "90_days_average_emission_rates": "0.026164424280350436",
  "30_days_average_standard_deviation": "0.013876815068493151",
  "7_days_average_standard_deviation": "0.011178021978021977",
  "30_days_average_emission_rates": "0.026164424280350436",
  "90_days_average_standard_deviation": "0.013876815068493151",
};

export const mockRecipients = ["test@test.com", "earthview@earthview.io"];
