import { Button, Form, DatePicker, Input, Select, Modal } from "antd";
import { Dayjs } from "dayjs";
import styles from "./style.module.scss";
import { FC, useState } from "react";
import { GpaqsAlarmIncident } from "../../../components/MapController/types";
import AlarmDetails from "../../../components/MapController/AlarmDetails/AlarmDetails";
import classNames from "classnames";
import { Node } from "../../../store/nodes/types";
import { Grid } from "../../SiteMapv2/types";
import RecalculateAlarms from "../../../components/RecalculateAlarms/RecalculateAlarms";
import EventConfidenceDisplay from "../../../components/EventConfidence/EventConfidence";
import {
  AlarmIncidentType,
  AlarmIncidentEnum,
  ConcentrationAlarmIncident,
} from "../../../store/orgAlarmIncidentsV2/types";

interface AlarmIncidentsPanelProps {
  setShowIncidentPanel: (newValue: boolean) => void;
  initSetIncidentActualLeakSource: (value: boolean) => void;
  incidentActualLeakSource: string;
  incidentType: string;
  setIncidentType: (newValue: string) => void;
  incidentNotes: string;
  setIncidentNotes: (newValue: string) => void;
  onSaveAlarmIncident: () => void;
  timezone: string;
  selectIncidentActualLeakSource: boolean;
  selectedAlarmIncident: null | AlarmIncidentType;
  showIncidentPanel: boolean;
  showPPMLayer: boolean;
  onCreateNewAlarmIncident: (newValues: FormValues) => void;
  siteNodes: Node[];
  siteGrid: Grid;
  alarmRollingAverageConfig: number | undefined;
  alertRollingAverageConfig: number | undefined;
  setAlarmBackcalculation: (newvalue: null | number) => void;
  recalculatedAverageEmissionsMCF: null | number;
  isAdmin: boolean;
}

interface FormValues {
  Timestamp: Dayjs;
  end_time: Dayjs;
  cause: string;
  incidentType: string;
  incidentNotes: string;
}

const AlarmIncidentPanel: FC<AlarmIncidentsPanelProps> = ({
  setShowIncidentPanel,
  selectedAlarmIncident,
  initSetIncidentActualLeakSource,
  incidentType,
  incidentNotes,
  setIncidentType,
  setIncidentNotes,
  onSaveAlarmIncident,
  timezone,
  incidentActualLeakSource,
  selectIncidentActualLeakSource,
  showIncidentPanel,
  showPPMLayer,
  onCreateNewAlarmIncident,
  siteNodes,
  siteGrid,
  setAlarmBackcalculation,
  recalculatedAverageEmissionsMCF,
  isAdmin,
}) => {
  const [showRecalculateModal, setShowRecalculateModal] = useState(false);
  const [showConfidenceModal, setShowConfidenceModal] = useState(false);

  const onFinish = (values: FormValues): void => {
    if (incidentActualLeakSource === "") {
      window.alert("no leak source selected in the map");
    } else {
      onCreateNewAlarmIncident(values);
    }
  };

  const gpaqsAlarmIncident = selectedAlarmIncident as GpaqsAlarmIncident;
  const concentrationAlarmIncident =
    selectedAlarmIncident as ConcentrationAlarmIncident;

  const EmptyAlarmIncidentPanel = (): JSX.Element => {
    return (
      <div>
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="Timestamp"
            label="Start Time"
            rules={[{ required: true, message: "Please select start time" }]}
          >
            <DatePicker
              showTime={{ format: "HH:mm" }}
              format={"YYYY-MM-DD HH:mm"}
            />
          </Form.Item>

          <Form.Item
            name="end_time"
            label="End Time"
            rules={[{ required: true, message: "Please select end time" }]}
          >
            <DatePicker
              showTime={{ format: "HH:mm" }}
              format={"YYYY-MM-DD HH:mm"}
            />
          </Form.Item>

          <Form.Item
            name="incidentType"
            label="Incident Type"
            rules={[{ required: true, message: "Please enter incident type" }]}
          >
            <Select>
              <Select.Option value="fugitive">Fugitive</Select.Option>
              <Select.Option value="operational">Operational</Select.Option>
              <Select.Option value="maintenance">Maintenance</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="incidentNotes"
            label="Incident Notes"
            rules={[
              { required: true, message: "Please enter incident notes" },
            ]}
          >
            <Input.TextArea placeholder="Notes" />
          </Form.Item>

          <div
            style={{
              height: 55,
            }}
          >
            <div
              style={{
                background: incidentActualLeakSource === "" ? "yellow" : "",
              }}
            >
              Selected leak Source in the map:{" "}
            </div>
            <b>{incidentActualLeakSource}</b>
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };

  return showIncidentPanel ? (
    <div
      className={classNames(styles.AlarmDetails)}
      style={{
        height: showPPMLayer ? "calc(100% - 210px)" : "100%",
        overflow: "auto",
        paddingBottom: 10,
      }}
    >
      <Button
        onClick={() => setShowIncidentPanel(false)}
        type="link"
        style={{ padding: 0 }}
      >
        Close
      </Button>
      <div>
        <Modal
          open={showConfidenceModal}
          onCancel={() => setShowConfidenceModal(false)}
          footer={[
            <Button
              key="closeConfidenceModal"
              onClick={() => setShowConfidenceModal(false)}
            >
              Close
            </Button>,
          ]}
          width={900}
          styles={{ body: { height: 600, overflow: "auto" } }}
        >
          {(selectedAlarmIncident != null) &&
          selectedAlarmIncident.type === AlarmIncidentEnum.GPAQS ? (
            <EventConfidenceDisplay
              siteId={
                selectedAlarmIncident?.SiteId !== undefined
                  ? selectedAlarmIncident?.SiteId
                  : ""
              }
              timestamp={
                selectedAlarmIncident?.Timestamp !== undefined
                  ? selectedAlarmIncident?.Timestamp
                  : ""
              }
              nodes={siteNodes}
              confidencePercentage={
                (selectedAlarmIncident as GpaqsAlarmIncident)?.most_likely_event
                  ?.ConfidencePercentage
              }
              coefficientofVariation={
                (selectedAlarmIncident as GpaqsAlarmIncident)?.most_likely_event
                  ?.coefficientOfVariation
              }
            />
              ) : null}
        </Modal>
      </div>
      <div>
        <Modal
          open={showRecalculateModal}
          title="Recalculate Alarm"
          onCancel={() => setShowRecalculateModal(false)}
          footer={null}
        >
          <RecalculateAlarms
            setAlarmBackcalculation={setAlarmBackcalculation}
            setShowRecalculateModal={setShowRecalculateModal}
            siteId={
              selectedAlarmIncident?.SiteId !== undefined
                ? selectedAlarmIncident?.SiteId
                : ""
            }
            incidentActualLeakSource={incidentActualLeakSource}
            timezone={timezone}
          />
        </Modal>
      </div>
      {selectedAlarmIncident === null ? (
        <EmptyAlarmIncidentPanel />
      ) : (
        ({
          [AlarmIncidentEnum.GPAQS]: (
            <AlarmDetails
              alarmRollingAverageConfig={
                gpaqsAlarmIncident.alarmRollingAverageConfig
              }
              alertRollingAverageConfig={
                gpaqsAlarmIncident.alertRollingAverageConfig
              }
              shouldAlarm={gpaqsAlarmIncident.should_alarm}
              siteNodes={siteNodes}
              timestamp={gpaqsAlarmIncident.most_likely_event?.Timestamp}
              recipients={gpaqsAlarmIncident.recipients}
              mostLikelyLeakEvent={gpaqsAlarmIncident.most_likely_event}
              mostLikelyLeakEventII={gpaqsAlarmIncident.most_likely_event_II}
              initSetIncidentActualLeakSource={initSetIncidentActualLeakSource}
              incidentType={incidentType}
              setIncidentType={setIncidentType}
              incidentNotes={incidentNotes}
              setIncidentNotes={setIncidentNotes}
              onSaveAlarmIncident={onSaveAlarmIncident}
              timezone={timezone}
              incidentActualLeakSource={incidentActualLeakSource}
              selectIncidentActualLeakSource={selectIncidentActualLeakSource}
              endTime={gpaqsAlarmIncident.end_time}
              siteGrid={siteGrid}
              selectedIncidentAlarmType={gpaqsAlarmIncident.type}
              nodeId={gpaqsAlarmIncident.NodeId}
              setShowRecalculateModal={setShowRecalculateModal}
              setShowConfidenceModal={setShowConfidenceModal}
              recalculatedAverageEmissionsMCF={recalculatedAverageEmissionsMCF}
              isAdmin={isAdmin}
            />
          ),
          [AlarmIncidentEnum.CONCENTRATION]: (
            <AlarmDetails
              ppm={Number(concentrationAlarmIncident.ppm)}
              siteNodes={siteNodes}
              timestamp={concentrationAlarmIncident.Timestamp}
              recipients={concentrationAlarmIncident.recipients}
              mostLikelyLeakEvent={undefined}
              mostLikelyLeakEventII={undefined}
              initSetIncidentActualLeakSource={initSetIncidentActualLeakSource}
              incidentType={incidentType}
              setIncidentType={setIncidentType}
              incidentNotes={incidentNotes}
              setIncidentNotes={setIncidentNotes}
              onSaveAlarmIncident={onSaveAlarmIncident}
              timezone={timezone}
              incidentActualLeakSource={incidentActualLeakSource}
              selectIncidentActualLeakSource={selectIncidentActualLeakSource}
              siteGrid={siteGrid}
              selectedIncidentAlarmType={selectedAlarmIncident.type}
              nodeId={selectedAlarmIncident.NodeId}
              setShowRecalculateModal={setShowRecalculateModal}
              setShowConfidenceModal={setShowConfidenceModal}
              recalculatedAverageEmissionsMCF={recalculatedAverageEmissionsMCF}
              isAdmin={isAdmin}
            />
          ),
        }[selectedAlarmIncident.type]) ?? <EmptyAlarmIncidentPanel />
      )}
    </div>
  ) : null;
};

export default AlarmIncidentPanel;
