import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { FC, useEffect, useState, ChangeEvent } from "react";
import UserModal from "../../components/UserModal/UserModal";
import { AppDispatch } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import { useGetOrganizationsQuery } from "../../store/organizations/api";
import { Organization } from "../../store/organizations/types";
import { setGoBackRoute, setPageTitle } from "../../store/ui/slice";
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
  useUpdateUserMutation,
} from "../../store/users/api";
import { User } from "../../store/users/types";
import styles from "./styles.module.scss";

interface UsersProps {
  isTest?: boolean;
}

const userActions = {
  NEW_USER: "NEW_USER",
  EDIT_USER: "EDIT_USER",
};

const Users: FC<UsersProps> = ({ isTest = false }) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [showUserPanel, setShowUserPanel] = useState(false);
  const [userPanelActionType, setUserPanelActionType] = useState<string>("");
  const { data, isError, isLoading } = useGetUsersQuery(null);
  const orgQueryResult = useGetOrganizationsQuery(null);
  const [createUser] = useCreateUserMutation();
  const [form] = Form.useForm();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [deleteUserMutation] = useDeleteUserMutation();
  const [updatUser] = useUpdateUserMutation();
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

  let organizations: Organization[] = [];
  let organizationNames: string[] = [];
  if (orgQueryResult.data !== undefined) {
    organizations = orgQueryResult.data;
    organizationNames = organizations.map((obj) => obj.name);
  }

  const colors: string[] = [
    "magenta",
    "purple",
    "red",
    "geekblue",
    "volcano",
    "blue",
    "orange",
    "cyan",
    "gold",
    "lime",
    "green",
  ];
  const orgMenuItems = organizationNames.map((name, i) => {
    return {
      key: i,
      label: name,
      value: name,
      color: colors[i] ?? "blue",
    };
  });

  const onOrgMenuChange = (selectedOrgs: string[]): void => {
    if (selectedOrgs.length === 0) {
      setFilteredUsers(users);
      return;
    }
    setFilteredUsers(
      users.filter((user) => {
        const userOrgs = user.organizations;
        for (const org of userOrgs) {
          // remove this when string[] is removed from User.organizations
          if (typeof org === "string") return false;
        }
        const userOrgNames: string[] = (userOrgs as Organization[]).map(
          (obj: Organization) => obj.name
        );
        return selectedOrgs.some((org) => userOrgNames.includes(org));
      })
    );
  };

  let users: User[] = [];
  if (!isError && !isLoading && data !== undefined) {
    users = data.map((user, index) => {
      return {
        ...user,
        key: index,
      };
    });
  }

  const parseOrganizations = (
    orgIdsArray: string[] | Organization[]
  ): Organization[] => {
    const parsedOrganizations: Organization[] = [];

    for (const val of orgIdsArray) {
      const orgObject = organizations.find((el) => el.OrgId === val);
      if (orgObject !== undefined) {
        parsedOrganizations.push(orgObject);
      }
    }
    return parsedOrganizations;
  };

  const onCreateUser = (values: User): void => {
    const isAdmin: boolean =
      values.isAdmin === undefined ? false : values.isAdmin;

    const organizationObjects =
      values.organizations === undefined
        ? []
        : parseOrganizations(values.organizations);

    const user: User = {
      ...values,
      isAdmin,
      email: values.email.toLowerCase(),
      organizations: organizationObjects,
    };

    createUser(user)
      .unwrap()
      .then(() => {
        window.alert("user created successfully");
        setShowUserPanel(false);
        setUserPanelActionType("");
        form.resetFields();
      })
      .catch((err) => console.log(err));
  };

  const onCloseModal = (): void => {
    form.resetFields();
    setShowUserPanel(false);
    setUserPanelActionType("");
    setSelectedUser(undefined);
  };

  const onStartUserDelete = (record: User): void => {
    setShowConfirmationModal(true);
    setSelectedUser(record);
  };

  const onStartUserEdit = (record: User): void => {
    const organizationsStrings = record.organizations.map((org) => {
      if (typeof org === "string" || org instanceof String) {
        return org;
      } else {
        return org.OrgId;
      }
    });
    form.setFields([
      {
        name: "userName",
        value: record.userName,
      },
      {
        name: "isAdmin",
        value: record.isAdmin,
      },
      {
        name: "email",
        value: record.email,
      },
      {
        name: "organizations",
        value: organizationsStrings,
      },
    ]);
    setSelectedUser(record);
    setUserPanelActionType(userActions.EDIT_USER);
    setShowUserPanel(true);
  };

  const onCancelUserDelete = (): void => {
    setShowConfirmationModal(false);
    setSelectedUser(undefined);
  };

  const onConfirmUserDelete = (): void => {
    if (selectedUser?.UserId !== undefined) {
      const payload = {
        email: selectedUser.email,
        userId: selectedUser.UserId,
      };
      deleteUserMutation(payload)
        .unwrap()
        .then((response) => {
          window.alert("The user has been deleted");
          onCancelUserDelete();
        })
        .catch((err) => {
          console.log(err);
          window.alert("error while deleting user");
          onCancelUserDelete();
        });
    }
  };

  const onEditUser = (values: User): void => {
    const organizationObjects = parseOrganizations(values.organizations);
    if (selectedUser?.UserId !== undefined) {
      updatUser({
        id: selectedUser.UserId,
        values: {
          userName: values.userName,
          isAdmin: values.isAdmin,
          organizations: organizationObjects,
        },
      })
        .unwrap()
        .then(() => {
          window.alert("The user has been updated");
        })
        .catch((e) => console.log(e));
    }
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    const search = e.target.value.toLowerCase();
    setFilteredUsers(
      users.filter((user) => {
        const emailBool = user.email.toLowerCase().includes(search);
        const nameBool = user.userName.toLowerCase().includes(search);
        return emailBool || nameBool;
      })
    );
  };

  const sortColumns = (a: string, b: string): number => {
    const aValue = a.toLowerCase();
    const bValue = b.toLowerCase();
    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  };

  const columns: ColumnsType<User> = [
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => sortColumns(a.email, b.email),
      key: "email",
    },
    {
      title: "Name",
      dataIndex: "userName",
      sorter: (a, b) => sortColumns(a.userName, b.userName),
      key: "userName",
    },
    {
      title: "Organizations",
      dataIndex: "organizations",
      key: "organization",
      render: (organizations: Organization[]) => {
        const orgNames: string[] = organizations.map((obj) => obj.name);
        if (orgNames[0] === "") return <div>{"-"}</div>;
        return orgNames.map((name, index) => {
          let color = "";
          if (orgMenuItems.length > 1) {
            const result = orgMenuItems.filter((obj) => obj.label === name);
            color = result.length > 0 ? result[0].color : "blue";
          }
          return (
            <Tag key={index} color={color} style={{ margin: "0.25rem" }}>
              {name}
            </Tag>
          );
        });
      },
    },
    {
      title: "User Type",
      dataIndex: "isAdmin",
      key: "isAdmin",
      render: (isAdmin: boolean) => {
        return <div>{isAdmin ? "EarthView Admin" : "Portal User"}</div>;
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record: User) => (
        <Space size="middle">
          <a onClick={() => onStartUserEdit(record)}>Edit</a>
          <a onClick={() => onStartUserDelete(record)}>Delete</a>
        </Space>
      ),
    },
  ];

  const edit: boolean = userPanelActionType === userActions.EDIT_USER;

  useEffect(() => {
    dispatch(setPageTitle("Users"));
    dispatch(setGoBackRoute(""));
  }, []);

  useEffect(() => {
    setFilteredUsers(users);
  }, [data]);

  return (
    <div>
      <UserModal
        organizations={organizations}
        edit={edit}
        onCreateUser={onCreateUser}
        onClosePanel={onCloseModal}
        form={form}
        open={showUserPanel}
        onFinishEdit={onEditUser}
      />

      <Modal
        open={showConfirmationModal}
        title="Confirm Delete User"
        onCancel={onCancelUserDelete}
        onOk={onConfirmUserDelete}
      >
        Are you sure you want to delete{" "}
        {selectedUser !== undefined ? selectedUser.email : ""}
      </Modal>
      <div style={{ display: "flex" }}>
        <div style={{ width: "17.5rem" }}>
          <Tooltip title="Search by email or name">
            <Input
              placeholder="Search..."
              allowClear
              onChange={(event) => onChangeSearch(event)}
            />
          </Tooltip>
        </div>
        <div style={{ marginLeft: "1rem" }}>
          <Tooltip title="Filter by organizations">
            <Select
              style={{ width: "15rem" }}
              mode="multiple"
              options={orgMenuItems}
              allowClear
              onChange={onOrgMenuChange}
            />
          </Tooltip>
        </div>
        <div className={styles.ButtonWrapper}>
          <Button
            type="primary"
            onClick={() => {
              setSelectedUser(undefined);
              setUserPanelActionType(userActions.NEW_USER);
              setShowUserPanel(true);
            }}
          >
            Create User
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={isTest ? users : filteredUsers}
        loading={isLoading}
        pagination={{ defaultPageSize: 8 }}
      />
    </div>
  );
};

export default Users;
