import { Switch } from "antd";
import styles from "./styles.module.scss";

export const LayersSwitch = (
  name: string,
  onChange: () => void,
  checked: boolean,
  disabled?: boolean
): JSX.Element => {
  return (
      <div className={styles.LayerSwitchWrapper}>
        <div>{name}</div>
        <Switch
          onChange={onChange}
          size="small"
          checked={checked}
          disabled={disabled}
        />
      </div>
  );
};
